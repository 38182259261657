.header_grid > ion-row > ion-col {
  display: flex;
  justify-content: center;
}

.content_graph > ion-col,
.content_title > ion-col {
  display: flex;
  justify-content: center;
}

.content_title {
  margin-top: 5vh;
}

.content_graph {
  margin-top: 5vh;
}

.header_room {
  margin-top: 3vh;
}
