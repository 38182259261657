.list-card_container {
  display: flex;
}

.list-card_container > div {
  margin: 0;
  height: 90%;
  display: flex;
  align-items: center;
  width: 360px;
  border-radius: 10px;
}

.list-card_container > div > ion-card {
  width: 100%;
  padding: 1vh 0;
}

.checkbox_container {
  width: 20px;
  height: 20px;
  border: 3px solid var(--ion-color-secondary);
  border-radius: 50%;
}

.checkbox {
  --background: var(--ion-color-light);
  /* --border-color: var(--ion-color-secondary); */
  --border-width: 0px;
  --border-color-checked: var(--ion-color-secondary);
  --checkmark-color: var(--ion-color-secondary);
  --background-checked: var(--ion-color-secondary);

  --size: 10px;
}

.checkbox::part(mark) {
  width: 50%;
}

.content_col {
  padding-top: 4vh;
}
