.title {
  text-align: center;
  margin-top: 3vh;
}

.title > ion-text > h2 {
  font-size: 7vw;
}

.graph {
  margin-top: 7vh;
}

.labels {
  margin-top: 4vh;
}

.label > ion-text {
  margin-left: 3vw;
}
