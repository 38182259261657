.header ion-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header > ion-toolbar {
  --p: 2.2vh;
  --padding-bottom: var(--p);
  --padding-top: var(--p);
}

.exit > ion-button > ion-icon {
  font-size: 4vw;
}

.extra_button > button {
  width: 100%;
  height: 100%;
}
.extra_button > button > ion-icon {
  font-size: 7vw;
}

@media screen and (min-width: 601px) {
  .exit > ion-button > ion-icon {
    font-size: 20px;
  }
}
