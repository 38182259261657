.header {
  margin-top: 1vh;
}

.header > ion-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header > ion-col > ion-icon {
  font-size: 6vw;
  font-weight: bold;
}

.header ion-button {
  height: fit-content;
}

.row ion-col {
  display: flex;
  justify-content: center;
  align-items: center;

  --ion-grid-column-padding: 0;
}

.row > ion-col p {
  text-align: center;
}
