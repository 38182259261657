.leaderboard-ranking {
  margin-top: 1vh;
  background-color: var(--ion-color-primary);
}

/* Header ---> */
.leaderboard-ranking-header p {
  font-size: 4vw;
  font-weight: 400;
  margin: 0;
}

.leaderboard-ranking-header > ion-row > ion-col:first-child > ion-text > p {
  text-align: start;
  margin-left: 15vw;
}
.leaderboard-ranking-header > ion-row > ion-col:last-child > ion-text > p {
  text-align: end;
  margin-right: 5vw;
}
/* <--- Header */

/* User ---> */
.leaderboard-user-item {
  --border-color: var(--ion-color-tertiary);
}
.leaderboard-user-item > ion-grid {
  margin: 0 3vw 0 5vw;
}

.leaderboard-user-item p {
  margin: 0;
}

.leaderboard-user-footprint > p {
  font-size: 4.5vw;
  font-weight: bold;

  text-align: end;
  margin-right: 5vw;
}

.leaderboard-user-name > p {
  font-size: 4.8vw;
}

.leaderboard-user-points > p {
  font-size: 3.2vw;
  margin-left: 5vw;
}

/* <--- User */
