.grid ion-label {
  margin: 0 2vw;
}

.grid ion-label p {
  font-size: 2.5vh;
}

.grid ion-input {
  font-size: 2.5vh;
}

.grid ion-input > input {
  padding: 1vh 2vw !important;
}

.grid > ion-row > ion-col {
  margin: 0 3vw;
}

.separator {
  width: 100%;
  border: 1px solid var(--ion-color-primary);
}
