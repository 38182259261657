.container ion-col {
  --ion-grid-column-padding: 0;
}

.container ion-button::part(native) {
  padding: 0;
  margin: 0;
}

.container ion-icon {
  font-size: 12.5vw;
}
