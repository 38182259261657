.radio_button {
  height: 100%;
  width: 100%;
  border: 2px solid var(--ion-color-secondary);
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.radio_button > div {
  width: 80%;
  height: 80%;
  border: 2px solid var(--ion-color-secondary);
  background-color: var(--ion-color-secondary);
  border-radius: 50%;
}
