.footer > ion-toolbar {
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.25);
}

.button_container {
  display: flex;
  justify-content: center;
}

.button_container > ion-button.sc-ion-buttons-ios-s,
.button_container > ion-button:not(.button-round) {
  width: 90%;
  --border-radius: 12px;
  height: 5vh;
}

.button_container p {
  font-size: 4.9vw;
}
