@media screen and (min-width: 601px) {
  .chivo12 {
    font-size: 12px;
  }

  .chivo14 {
    font-size: 14px;
  }
  .chivo16 {
    font-size: 16px;
  }

  .chivo18 {
    font-size: 18px;
    font-weight: bold;
  }

  .chivo20 {
    font-size: 20px;
  }

  .chivo22 {
    font-size: 22px;
  }

  .chivo24 {
    font-size: 24px;
    font-weight: bold;
  }

  .chivo30 {
    font-size: 30px;
  }

  .chivo32 {
    font-size: 32px;
    /* font-size: 3.95vh; */
  }

  .tag {
    display: flex;
    align-items: center;
  }

  .tag > p {
    height: fit-content;
  }
}

@media screen and (max-width: 600px) {
  .chivo12 {
    font-size: 3.1vw;
  }

  .chivo14 {
    font-size: 14px;
  }
  .chivo16 {
    font-size: 16px;
  }

  .chivo18 {
    font-size: 5vw;
    font-weight: bold;
  }

  .chivo20 {
    font-size: 5.4vw;
    font-weight: bold;
  }

  .chivo22 {
    font-size: 5.9vw;
    font-weight: bold;
  }

  .chivo24 {
    font-size: 6.2vw;
    font-weight: bold;
  }

  .chivo30 {
    font-size: 6vw;
    font-weight: bold;
  }

  .chivo32 {
    font-size: 8vw;
    font-weight: bold;
  }

  .tag {
    display: flex;
    align-items: center;
  }

  .tag > p {
    height: fit-content;
  }
}
