.clicker_picker {
  width: 100%;
  height: fit-content;
  padding: 0vh 0;
  padding-bottom: 0.7vh;
  padding-bottom: 5px;
  /* background-color: red; */
}

.clicker_picker > ion-col {
  display: flex;
  justify-content: space-between;
}
