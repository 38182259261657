.container {
  --ion-grid-padding: 0;
}

.container ion-col {
  --ion-grid-column-padding: 0;

  display: flex;
  justify-content: center;
  align-items: center;
}

.container h1,
.container p {
  text-align: center;
}

.row1 {
  margin-top: 4vh;
  /* background-color: green; */
}

.row2 {
  margin-top: 3vh;
  /* background-color: blue; */
}

.calendar ion-button,
.graph_type ion-button {
  /* background-color: red; */
  font-size: 4vw;
}

.calendar ion-button::part(native),
.graph_type ion-button::part(native) {
  padding: 0;
  margin: 0;
}

.hotel_name > ion-text > h1 {
  font-size: 6.5vw;
}

.timeline > ion-text > p {
  font-size: 4vw;
}
