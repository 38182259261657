.container {
  display: flex;
  align-items: center;
  /* justify-content: flex-end; */

  /* width: 100%; */
}

.container p {
  font-size: 2.3vw;
  text-align: center;
  text-justify: center;

  margin: 0;
}

.container > ion-icon {
  font-size: 3vw;
  margin-right: 1vw;
}
