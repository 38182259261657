/* Hotel Info --- */
.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.container > ion-text > p {
  margin: 0;
}

.hotel_name > p {
  font-size: 5vw;
  font-weight: bold;
}

.timespan > p {
  font-size: 2.5vw;
}

/* --- Hotel Info */
