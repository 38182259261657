.current_res-card-header {
  display: flex;
  justify-content: space-between;
  padding: 1.5vh 5vw 1.5vh 5vw;
}

/* Title --- */
.current_res-card-header > div > ion-card-title > h4 {
  margin: 0;
  font-size: 20px;
  font-size: 5vw;
  font-weight: bold;
}
/* --- Title */

/* Subtitle --- */
.current_res-card-header > div > ion-card-subtitle {
  display: flex;
  margin: 0;
  margin-top: 0.4vh;
  width: fit-content;
  max-width: 50vw;
}

.current_res-card-header > div > ion-card-subtitle > ion-icon {
  font-size: 3vw;
}

.current_res-card-header > div > ion-card-subtitle > p {
  font-weight: 100;
  margin: 0;
  margin-right: 1vw;
  font-size: 2.5vw;
  align-self: center;
}
/* --- Subtitle */

/* Date --- */
.current_res-card-header-date {
  margin-top: 1.5vh;
  height: fit-content;
}

.current_res-card-header-date > p {
  font-size: 2.3vw;
  margin: 0;
}
/* --- Date */
