.button_container {
  display: flex;
  justify-content: center;
}

.button_container > ion-button.sc-ion-buttons-ios-s,
.button_container > ion-button:not(.button-round) {
  /* width: 90%; */
  --border-radius: 12px;
  height: 5vh;
  min-height: 40px;
}

.small_text p {
  font-size: 3.8vw;
}
