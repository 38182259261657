.grid {
  border-radius: 12px;
  --ion-grid-padding: 0;
  /* height: 100%; */
}

.grid ion-button.arrow {
  height: 5vh;
}
.grid > ion-row ion-col {
  --ion-grid-column-padding: 0;
  display: flex;
  align-items: center;
}

.center {
  display: flex;
  align-items: center;
}

.grid ion-button.arrow::part(native) {
  margin: 0;
  padding: 0;
}

.right,
.left {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider {
  width: 100%;
}

.slider ion-col {
  justify-content: center;
}

.icon_next_to_text {
  margin-left: 15px;
  font-size: 25px;
}

@media screen and (min-width: 601px) {
  .grid {
    width: 350px;
  }

  .icon > ion-icon {
    font-size: 20px;
  }
}

@media screen and (max-width: 600px) {
  .grid {
    margin: 0 5vw;
  }

  .grid ion-icon {
    font-size: 3.5vw;
  }

  .icon > ion-icon {
    /* width: 10px; */
    font-size: 4vw;
  }

  .slider ion-col > ion-icon {
    font-size: 4.5vw;
  }

  .grid ion-button {
    height: 5.5vh;
  }

  .main_text > ion-button {
    --padding-start: 0;
    --padding-end: 0;
    --padding-top: 0;
    --padding-bottom: 0;
  }
}
