.card,
.card > ion-card-content {
  padding: 0;
}

.right,
.left {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.left {
  align-items: flex-start;
}

.right {
  align-items: flex-end;
}

.estimated_value > ion-col {
  display: flex;
  align-items: center;
}
