.options_picker {
  width: 100%;
  height: fit-content;
  padding: 0vh 0;
  padding-bottom: 0.7vh;
  padding-bottom: 5px;
  /* background-color: red; */
}

.options_picker > ion-col {
  display: flex;
  justify-content: space-between;
}

.options_picker > ion-col p:last-child {
  /* margin-right: 2vw; */
}
