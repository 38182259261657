#navbar {
  height: 9%;
}

#navbar ion-tab-button {
  --color-selected: var(--ion-color-secondary);
}

#navbar > ion-tab-button > ion-label {
  font-family: Chivo;
  font-size: 3.2vw;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: center;
  margin: 0;
}

#navbar > ion-tab-button > ion-icon {
  font-size: 8vw;
}

#navbar > ion-tab-button[tab="alerts"] {
  padding-top: 1.5vw;
}
#navbar > ion-tab-button[tab="alerts"] > ion-icon {
  font-size: 6.5vw;
}
#navbar > ion-tab-button[tab="hotels"] {
  padding-top: 1vw;
}
#navbar > ion-tab-button[tab="hotels"] > ion-icon {
  font-size: 7vw;
}
#navbar > ion-tab-button[tab="rewards"] {
  padding-top: 1.5vw;
}
#navbar > ion-tab-button[tab="rewards"] > ion-icon {
  font-size: 6.5vw;
}

@media (min-width: 500px) {
  #navbar > ion-tab-button > ion-label {
    font-family: Chivo;
    font-size: 3vw;
    font-style: normal;
    font-weight: 200;
    letter-spacing: 0em;
    text-align: center;
    margin: 0;
  }

  #navbar > ion-tab-button > ion-icon {
    font-size: 4vw;
  }
}
