.button {
  display: flex;
  justify-content: center;
}

.button > ion-button {
  height: fit-content;
  border-radius: 10px;
}

.button > ion-button::part(native) span {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 600px) {
  .button > ion-button::part(native) {
    height: 5vh;
  }

  .icon {
    font-size: 6vw;
  }

  .button > ion-button > ion-text {
    margin-left: 3vw;
  }
}

@media screen and (min-width: 601px) {
  .button > ion-button::part(native) {
    height: 50px;
    /* height: 5vh; */
  }

  .icon {
    font-size: 25px;
  }

  .button > ion-button > ion-text {
    margin-left: 10px;
  }
}
