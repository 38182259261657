.friends,
.avatar {
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
}

.name {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.avatar > ion-avatar {
  width: 17vw;
  height: 17vw;
  border: 3px var(--ion-color-primary) solid;
}
