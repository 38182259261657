@import url("https://fonts.googleapis.com/css?family=Chivo");
@import url("https://fonts.googleapis.com/css?family=Montserrat");

#root ion-tabs {
  position: relative;
}

button {
  background: none;
}

button:hover {
  opacity: 50%;
}

.sc-ion-modal-ios-h {
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.4);
}

.card-content-ios p {
  font-size: inherit;
}

p,
h1,
h2,
h3,
h4,
h5 {
  text-transform: none;
}

ion-item {
  --padding-start: 0;
  --padding-end: 0;
  --padding-left: 0;
  --padding-right: 0;
  --inner-padding-start: 0;
  --inner-padding-end: 0;
  --inner-padding-left: 0;
  --inner-padding-right: 0;
}

.montserrat {
  font-family: Montserrat;
}

.chivo {
  font-family: Chivo;
}

ion-loading > .loading-wrapper {
  --spinner-color: var(--ion-color-primary);
  display: flex;
  flex-direction: column;
}

ion-loading .loading-wrapper > .loading-content {
  color: var(--ion-color-secondary);
  margin-top: 2vh;
}

.fixed {
  background: white;
  left: 0%;
  right: 0%;
}

p,
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}

ion-card {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.hidden {
  visibility: "hidden";
}

.shadow_b {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.shadow_t {
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.25);
}

.flexr-center,
.flexc-end,
.flexr-start,
.flexr-end,
.flexc-center,
.flexr-bot_left,
.flexr-bot_center,
.flexr-sb {
  display: flex;
}

.flexr-center,
.flexc-center,
.flexr-start,
.flexr-sb {
  align-items: center;
}
.flexr-center,
.flexc-center,
.flexr-bot_center {
  justify-content: center;
}

.flexr-sb {
  justify-content: space-between;
}

.flexr-center,
.flexr-end,
.flexr-start,
.flexr-bot_left,
.flexr-sb {
  flex-direction: row;
}

.flexc-center,
.flexc-end {
  flex-direction: column;
}

.flexr-end {
  justify-content: flex-end;
}
.flexc-end,
.flexr-bot_center {
  align-items: flex-end;
}
.flexr-start {
  justify-content: flex-start;
}

.flexr-bot_left {
  justify-content: flex-start;
  align-items: flex-start;
}
