.tip_card-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tip_card {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-top: 2vh;
  margin-bottom: 1vh;
}

/* ! Title ---> */
.tip_card > ion-card-title {
  padding-left: 4vw;
  padding-top: 1vh;
}
.tip_card > ion-card-title > ion-item > ion-icon {
  font-size: 8vw;
}
.tip_card > ion-card-title > ion-item > ion-text {
  margin-left: 3vw;
}
.tip_card > ion-card-title > ion-item > ion-text > p {
  font-size: 5vw;
}
/* ! <--- Title */

/* ! Content ---> */
.tip_card > ion-card-content {
  padding: 0;
  padding-left: 15vw;
  padding-right: 4vw;
  padding-bottom: 1vh;
}

.tip_card > ion-card-content > ion-text > p {
  font-size: 3.8vw;
}
/* ! <--- Content */
