.content > div {
  margin-top: 10vh;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content > div > ion-grid {
  max-height: 100%;
}

.content > ion-grid h3 {
  font-size: 5vw;
  font-weight: bold;
}

/* .content > ion-grid {
  --ion-grid-padding: 3vw;
} */

.content > ion-grid > ion-row > ion-col:last-child {
  display: flex;
  justify-content: flex-end;
}

.content ion-textarea {
  border: 1px var(--ion-color-secondary) solid;
  --background: #fcfaf1;
  --color: var(--ion-color-secondary);

  --padding-start: 1vh;
  --padding-end: 1vh;

  min-height: 40vh;
}

.title {
  background: var(--ion-color-light);
  width: 100%;

  --ion-grid-padding: 3vw;
}

@media (min-width: 600px) {
  .title {
    --ion-grid-padding: 30px;
  }
}
