.segments {
  margin: 0 5vw;
}

.segments ion-segment {
  --background: none;
}

.segments ion-segment-button {
  --background: var(--ion-color-light);
  --background-checked: var(--ion-color-light);
  --border-color: var(--ion-color-light);

  --indicator-color: none;
  --indicator-box-shadow: none;
}

.segments ion-segment-button::part(indicator) {
  border: 1px var(--ion-color-secondary) solid;
  border-left: none;
  border-right: none;
  border-top: none;
}
