.content {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 600px) {
  .input {
    margin: 0 5vw;
    margin-top: 2vh;
  }
}
@media screen and (min-width: 601px) {
  .input {
    margin: 0 30px;
    margin-top: 2vh;
  }
}
