/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
/* :root { */
body,
body.light {
  --ion-color-primary: #62cd90;
  --ion-color-primary-rgb: 98, 205, 144;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #56b47f;
  --ion-color-primary-tint: #72d29b;

  --ion-color-primary_dim: #62c890;
  --ion-color-primary_dim-rgb: 98, 200, 144;
  --ion-color-primary_dim-contrast: #000000;
  --ion-color-primary_dim-contrast-rgb: 0, 0, 0;
  --ion-color-primary_dim-shade: #56b07f;
  --ion-color-primary_dim-tint: #72ce9b;

  --ion-color-secondary: #1a697f;
  --ion-color-secondary-rgb: 26, 105, 127;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #175c70;
  --ion-color-secondary-tint: #31788c;

  --ion-color-tertiary: #d7e9bc;
  --ion-color-tertiary-rgb: 215, 233, 188;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #bdcda5;
  --ion-color-tertiary-tint: #dbebc3;

  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #ff8373;
  --ion-color-danger-rgb: 255, 131, 115;
  --ion-color-danger-contrast: #000000;
  --ion-color-danger-contrast-rgb: 0, 0, 0;
  --ion-color-danger-shade: #e07365;
  --ion-color-danger-tint: #ff8f81;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #c4c4c4;
  --ion-color-medium-rgb: 196, 196, 196;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #acacac;
  --ion-color-medium-tint: #cacaca;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-color-secondary_dim: #77a3b1;

  --ion-color-beje: #fcfaf1;
  --ion-color-beje-rgb: 252, 250, 241;
  --ion-color-beje-contrast: #000000;
  --ion-color-beje-contrast-rgb: 0, 0, 0;
  --ion-color-beje-shade: #dedcd4;
  --ion-color-beje-tint: #fcfbf2;

  /* NAVBAR */
  --ion-color-navbar: #1a697f;
  --ion-color-navbar-rgb: 26, 105, 127;
  --ion-color-navbar-contrast: #ffffff;
  --ion-color-navbar-contrast-rgb: 255, 255, 255;
  --ion-color-navbar-shade: #175c70;
  --ion-color-navbar-tint: #31788c;

  /* MENU */
  --ion-color-menu_button: #1a697f;
  --ion-color-menu_button-rgb: 26, 105, 127;
  --ion-color-menu_button-contrast: #ffffff;
  --ion-color-menu_button-contrast-rgb: 255, 255, 255;
  --ion-color-menu_button-shade: #175c70;
  --ion-color-menu_button-tint: #31788c;

  /* BACKGROUND */
  --ion-color-background: #f4f5f8;
  --ion-color-background-rgb: 244, 245, 248;
  --ion-color-background-contrast: #000000;
  --ion-color-background-contrast-rgb: 0, 0, 0;
  --ion-color-background-shade: #d7d8da;
  --ion-color-background-tint: #f5f6f9;

  --ion-color-woocommerce: #9b5c8f;
  --ion-color-woocommerce-rgb: 155, 92, 143;
  --ion-color-woocommerce-contrast: #ffffff;
  --ion-color-woocommerce-contrast-rgb: 255, 255, 255;
  --ion-color-woocommerce-shade: #88517e;
  --ion-color-woocommerce-tint: #a56c9a;
}

body.dark {
  --ion-color-primary: #363739;
  --ion-color-primary-rgb: 54, 55, 57;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #303032;
  --ion-color-primary-tint: #4a4b4d;

  --ion-color-secondary: #404144;
  --ion-color-secondary-rgb: 64, 65, 68;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #38393c;
  --ion-color-secondary-tint: #535457;

  /* NAVBAR */
  --ion-color-navbar: #2a2b2e;
  --ion-color-navbar-rgb: 42, 43, 46;
  --ion-color-navbar-contrast: #ffffff;
  --ion-color-navbar-contrast-rgb: 255, 255, 255;
  --ion-color-navbar-shade: #252628;
  --ion-color-navbar-tint: #3f4043;

  /* BACKGROUND */
  --ion-color-background: #1f2023;
  --ion-color-background-rgb: 31, 32, 35;
  --ion-color-background-contrast: #ffffff;
  --ion-color-background-contrast-rgb: 255, 255, 255;
  --ion-color-background-shade: #1b1c1f;
  --ion-color-background-tint: #353639;

  /* MENU */
  --ion-color-menu_button: #f4f5f8;
  --ion-color-menu_button-rgb: 244, 245, 248;
  --ion-color-menu_button-contrast: #000000;
  --ion-color-menu_button-contrast-rgb: 0, 0, 0;
  --ion-color-menu_button-shade: #d7d8da;
  --ion-color-menu_button-tint: #f5f6f9;

  /* HEADER */
  --ion-color-header: #2a2b2e;
  --ion-color-header-rgb: 42, 43, 46;
  --ion-color-header-contrast: #ffffff;
  --ion-color-header-contrast-rgb: 255, 255, 255;
  --ion-color-header-shade: #252628;
  --ion-color-header-tint: #3f4043;
}

.ion-color-background {
  --ion-color-base: var(--ion-color-background);
  --ion-color-base-rgb: var(--ion-color-background-rgb);
  --ion-color-contrast: var(--ion-color-background-contrast);
  --ion-color-contrast-rgb: var(--ion-color-background-contrast-rgb);
  --ion-color-shade: var(--ion-color-background-shade);
  --ion-color-tint: var(--ion-color-background-tint);
}

.ion-color-header {
  --ion-color-base: var(--ion-color-header);
  --ion-color-base-rgb: var(--ion-color-header-rgb);
  --ion-color-contrast: var(--ion-color-header-contrast);
  --ion-color-contrast-rgb: var(--ion-color-header-contrast-rgb);
  --ion-color-shade: var(--ion-color-header-shade);
  --ion-color-tint: var(--ion-color-header-tint);
}

.ion-color-navbar {
  --ion-color-base: var(--ion-color-navbar);
  --ion-color-base-rgb: var(--ion-color-navbar-rgb);
  --ion-color-contrast: var(--ion-color-navbar-contrast);
  --ion-color-contrast-rgb: var(--ion-color-navbar-contrast-rgb);
  --ion-color-shade: var(--ion-color-navbar-shade);
  --ion-color-tint: var(--ion-color-navbar-tint);
}
.ion-color-menu_button {
  --ion-color-base: var(--ion-color-menu_button);
  --ion-color-base-rgb: var(--ion-color-menu_button-rgb);
  --ion-color-contrast: var(--ion-color-menu_button-contrast);
  --ion-color-contrast-rgb: var(--ion-color-menu_button-contrast-rgb);
  --ion-color-shade: var(--ion-color-menu_button-shade);
  --ion-color-tint: var(--ion-color-menu_button-tint);
}

.ion-color-primary_dim {
  --ion-color-base: var(--ion-color-primary_dim);
  --ion-color-base-rgb: var(--ion-color-primary_dim-rgb);
  --ion-color-contrast: var(--ion-color-primary_dim-contrast);
  --ion-color-contrast-rgb: var(--ion-color-primary_dim-contrast-rgb);
  --ion-color-shade: var(--ion-color-primary_dim-shade);
  --ion-color-tint: var(--ion-color-primary_dim-tint);
}

.ion-color-secondary_dim {
  --ion-color-base: var(--ion-color-secondary_dim);
}

.ion-color-beje {
  --ion-color-base: var(--ion-color-beje);
  --ion-color-base-rgb: var(--ion-color-beje-rgb);
  --ion-color-contrast: var(--ion-color-beje-contrast);
  --ion-color-contrast-rgb: var(--ion-color-beje-contrast-rgb);
  --ion-color-shade: var(--ion-color-beje-shade);
  --ion-color-tint: var(--ion-color-beje-tint);
}

.ion-color-woocommerce {
  --ion-color-base: var(--ion-color-woocommerce);
  --ion-color-base-rgb: var(--ion-color-woocommerce-rgb);
  --ion-color-contrast: var(--ion-color-woocommerce-contrast);
  --ion-color-contrast-rgb: var(--ion-color-woocommerce-contrast-rgb);
  --ion-color-shade: var(--ion-color-woocommerce-shade);
  --ion-color-tint: var(--ion-color-woocommerce-tint);
}
