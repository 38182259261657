.header {
  background: #ffffff;
  /* margin-top: 2vh; */
}

/*  Header Border --- */
.header-auth {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  --ion-grid-padding: 0;
}

.header > ion-toolbar {
  /* padding-top: 2vh; */
  --border-style: none;
}

.header::after {
  display: none;
}
/* --- Header Border */

.header > ion-toolbar > ion-grid {
  padding-top: 2.6vh;
}

.header-auth > ion-toolbar > ion-grid > ion-row > ion-col {
  --ion-grid-column-padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-auth-backButton > ion-button {
  width: fit-content;
  height: fit-content;
}
.header-auth-backButton > ion-button > ion-icon {
  width: 15vw;
  height: 3.5vh;
}

.header-logo > ion-icon {
  width: 33vw;
  height: 6vh;
}

.header-menu ion-icon,
.header-non_auth-home ion-icon {
  width: 9.2vw;
  height: 5vh;
}
