.container {
  margin-bottom: 0.6vh;
}

.container ion-datetime {
  padding: 0;
  --padding-start: 0;
  /* --padding-end: 3vw; */
  --padding-top: 0;
  --padding-bottom: 0;
  margin-right: 5vw;
}

.container ion-button {
  height: fit-content;
  padding: 0;
}

.container ion-datetime {
  margin-right: 19px;
}

.container ion-datetime::part(text),
.container ion-datetime::part(placeholder) {
  font-family: Montserrat;
  font-size: 3.8vw;
  color: var(--ion-color-secondary);
  /* vertical-align: center; */
  /* text-align: center; */
  /* margin-right: 0; */
  /* --padding-end: 0;
  --padding-start: 0;
  --padding-top: 0;
  --padding-bottom: 0; */
}

@media (min-width: 600px) {
  .container ion-datetime::part(text),
  .container ion-datetime::part(placeholder) {
    font-size: 16px;
  }
}
@media (max-width: 599px) {
  .container ion-datetime::part(text),
  .container ion-datetime::part(placeholder) {
    font-size: 3.8vw;
  }
}
