.header_timespan ion-icon {
  font-size: 20px;
}

.labels {
  margin: 0 300px;
  margin-top: 2vh;
  margin-bottom: 1vh;
}

.labels > ion-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.labels_mid_col > ion-text {
  margin-left: 10px;
}

.labels_mid_col > ion-icon {
  font-size: 25px;
}

.room_info {
  margin: 0 300px;
}

.room_info > ion-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bar > ion-progress-bar {
  /* .bar { */
  --background: #77a3b1;
  /* --background: red; */
  --progress-background: red;
  height: 14px;
  border-radius: 10px;

  /* width: 10%; */
}
.bar > ion-progress-bar::part(progress) {
  color: red;
}

.rewards_title {
  margin-top: 2vh;
}
