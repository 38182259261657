.title{
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    font-weight: bolder;
    color: #73CE90;
    padding-bottom: 15px;
    padding-top: 15px;
    margin-top:8px;
    text-align: center;
}
.header{
    background-color: white;
    line-height: normal;
    position: fixed;
    padding-bottom: 15px;
    padding-top:5px;
    border-top: 1px solid #62CD90;
    border-left: 1px solid #62CD90;
    border-right: 1px solid #62CD90;
    width: 100%;
    box-shadow: 0px 7px 6px -6px darkgrey; 
    
}

.contentTitle {
    text-align: justify;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: bold;
    color: #1A697F;
    padding-top: 15px;
    padding-left:15px;
    padding-right: 15px;
    border-top: 0.5px solid #DCF2C4;

}

.question{
    text-align: left;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    padding-top: 15px;
    padding-left:10px;
    padding-right: 10px;
}

.answer{
    text-align: left;
    font-family: "Inter", sans-serif;
    font-size: 11px;
    line-height: 16px;
    color:#1A697F;
    padding-top: 8px;
    padding-left: 20px;
    padding-right: 20px;
}

.privacySection{
    background-color: #ffff;
    color: #1A697F;
    height: fit-content;
    overflow-y: scroll;
    width: 100%;
    border: 1px solid #62CD90;
}

.closing{
    color: #EA7F72;
    position:fixed;
    margin-left: 20px;
    margin-top: 20px;
    font-size: 24px;
}

.indice{
    text-align: left;
    font-family: "Inter", sans-serif;
    font-size: 15px;
    line-height: 16px;
    color:#1A697F;
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
}

@media(min-width:600px){
    .contactData {
        width:500px;
    }
}


