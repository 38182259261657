.input-item {
  width: 100%;
}

.input-item > ion-label > p {
  font-size: 4vw;
}

.input-item > ion-input {
  font-size: 4vw;
}
