.signup-page > .form-container > .form-form {
  margin-bottom: 5vh;
}

.signup-page > .form-container > .form-form > .button-item {
  margin-top: 6vh;
}

.greeting-thankyou_row {
  margin: 13vh 0;
  margin-bottom: 0;
}

.greeting-points_row {
  margin-top: 3vh;
}

.greeting-button_row {
  margin-top: 8vh;
}

.greeting-code_row {
  margin-top: 8vh;
}

.greeting-description {
  margin-top: 2vh;
}
