@media screen and (max-width: 600px) {
  .textarea {
    margin: 0 4vw;
  }
  .title {
    margin: 0 2vw;
  }
  .description_title {
    margin: 0 4vw;
  }
}
@media screen and (min-width: 601px) {
  .textarea {
    margin: 0 10px;
  }
  .title {
    margin: 0 10px;
  }
  .description_title {
    margin: 0 10px;
  }
}

.grid > ion-row {
  margin-top: 0.5vh;
}
