
.skeleton {
  display: flex;
  justify-content: space-between;

  margin: 0 5vw;
}

.skeleton ion-skeleton-text {
  border-radius: 15px;
}

.list_button {
  width: 100%;
  height: fit-content;
  margin: 0;

  --padding-start: 0;
  --padding-end: 0;
  --padding-top: 0;
  --padding-bottom: 0;
}

.button-content {
  display: flex;
  justify-content: space-between;
  width: 100%;

  --ion-grid-column-padding: 0;
}
