@media screen and (max-width: 600px) {
  .input {
    --padding-start: 3vw;
    --padding-end: 3vw;
    --padding-top: 1vh;
    --padding-bottom: 1vh;
  }
}
@media screen and (min-width: 601px) {
  .input {
    --padding-start: 10px;
    --padding-end: 10px;
    --padding-top: 7px;
    --padding-bottom: 7px;
  }
}
