.item {
  margin: 0 5vw;
  margin-top: 1vh;

  --border-color: var(--ion-color-tertiary);
}

.item:first-child {
  margin-top: 1.5vh;
}

.grid {
  --ion-grid-padding: 0;
}

.grid > ion-row:first-child > ion-col {
  --ion-grid-column-padding: 0;
}
