#root {
  --login_signup-title-margin-top: 10vh;
  --login_signup-form-margin-top: 10vh;
  --login_signup-form-button-width: 45%;
  --login_signup-form-button-height: 6vh;
}

.login_singup-page > .form-container,
#complete_profile-content > .form-container {
  margin-top: 6vh;
}

.login_singup-page > .form-container > .form-form {
  margin-top: 0vh;
  /* padding-bottom: 5vh; */
}

.login_singup-page > .form-container > .form-form > .button-item {
  width: 45%; /* button width */
  margin: 0 auto;
}

.login_singup-page > .form-container > .form-form > .button-item > ion-button {
  height: 6vh;
  min-height: 40px;
}
