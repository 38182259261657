.modal {
  --min-height: 200px;
  /* --max-height: 200px; */
  --max-width: 90%;
  --border-radius: 10px;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.modal > .modal-wrapper {
  position: absolute;
  top: 20%;
}

.modal > ion-backdrop {
  filter: blur(0px);
}

.height1 {
  --height: 26vh;
}
.height2 {
  --height: 35vh;
}

@media screen and (min-width: 601px) {
  .modal {
    --width: 500px;
  }
}
