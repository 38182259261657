ion-menu#menu {
  --width: 50vw;
  /* width: 130vw; */
}

.menu-header {
  padding-top: 3vh;
}

.menu-logo > ion-img {
  position: absolute;
  right: 5%;
  width: 85%;
  height: auto;
}

.menu-close {
  color: var(--ion-color-light);
}

.menu-close ion-icon {
  font-size: 8vw;
}

.menu-footer {
  border-top: 1px solid rgba(26, 105, 127, 0.4);
  height: 9vh;

  position: absolute;
  bottom: 0%;
  left: 0%;
  right: 0%;

  display: flex;
  align-items: center;
}

.menu-footer > ion-item {
  width: 100%;
}

.menu-footer ion-grid {
  --ion-grid-padding: 2.6vh;
  padding-inline-end: 0;
  padding-inline-start: 0;
}

.menu-footer-icon {
  display: flex;
  margin-left: 3vw;
  align-items: center;
}
