.button-item > ion-button {
  position: relative;
  overflow: hidden;

  margin: 0;

  height: 100%;
  width: 100%;
}

.button-item > ion-button::part(native) {
  margin: 0;
  padding: 0;
  border-radius: 9px;
}

.button-item > ion-button > ion-text > p {
  font-size: 4.6vw;
  font-weight: bold;

  margin: 0;
}
