#page {
  --launch-page-buttons-width: 47%;
}

.item-logo {
  margin-top: 25vh;
}

.item-logo::part(native) {
  flex-direction: column;
  align-items: center;
}

.logo {
  width: 80%;
}

.grid {
  margin-top: 10vh;
  width: 90%;
}

.button {
  width: 100%;
  height: 7vh;
}
