.additional_styles_to_markdown_hotel_description h2,
.additional_styles_to_markdown_hotel_description h1,
.additional_styles_to_markdown_hotel_description h3,
.additional_styles_to_markdown_hotel_description h4,
.additional_styles_to_markdown_hotel_description h5,
.additional_styles_to_markdown_hotel_description h6,
.additional_styles_to_markdown_hotel_description p {
  color: var(--ion-color-secondary);
}

.additional_styles_to_markdown_hotel_description h1,
.additional_styles_to_markdown_hotel_description h2,
.additional_styles_to_markdown_hotel_description h3 {
  font-weight: bold;
}

.additional_styles_to_markdown_hotel_description h1.green,
.additional_styles_to_markdown_hotel_description h2.green,
.additional_styles_to_markdown_hotel_description h3.green,
.additional_styles_to_markdown_hotel_description h4.green,
.additional_styles_to_markdown_hotel_description h5.green,
.additional_styles_to_markdown_hotel_description h6.green,
.additional_styles_to_markdown_hotel_description p.green {
  color: var(--ion-color-primary);
}
