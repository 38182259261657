/* Data --- */

.container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.data-top p {
    font-weight: bold;
    font-size: 4.5vw;
}

.data-top p>small {
    font-size: 2.5vw;
}

.data-bottom p {
    font-size: 3.7vw;
}


/* --- Data */