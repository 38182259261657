.spending_data-item {
  display: flex;
  flex-direction: column;
}

.spending_data-item-label > ion-label > p {
  margin: 0;
  font-size: 2.6vw;
}

.spending_data-item-content {
  margin-top: 0.5vh;
  display: flex;
  /* justify-content: space-between; */
}

.spending_data-item-content > ion-text {
  margin-left: 3vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spending_data-item-content > ion-text > p {
  font-family: Chivo;
  font-size: 5.5vw;
  text-align: left;
}

.spending_data-item-content > ion-text > p > small {
  font-size: 3.4vw;
}
