.header {
  margin-top: 2vh;
}

.header > ion-col {
  display: flex;
  align-items: center;

  margin: 0 6vw;
}

.header_right {
  justify-content: flex-end;
}
.header_right > ion-icon {
  font-size: 7vw;
}

.header_left > ion-icon {
  font-size: 5vw;
}
.header_left > ion-text {
  margin-left: 3vw;
}
