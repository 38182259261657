@media screen and (max-width: 600px) {
  .text10 {
    font-size: 2.7vw !important;
  }

  .text11 {
    font-size: 2.9vw !important;
  }

  .text12 {
    font-size: 3.2vw !important;
  }

  .text14 {
    font-size: 3.8vw !important;
  }

  .text16 {
    font-size: 4.2vw !important;
  }

  .text18 {
    font-size: 4.5vw !important;
    font-weight: bold;
  }

  .text20 {
    font-size: 5.3vw !important;
  }

  .text22 {
    font-size: 22px !important;
  }

  .tag {
    display: flex;
    align-items: center;
  }

  .tag > p {
    height: fit-content;
    text-align: justify;
    margin: 0;
    padding: 0;
  }
}
@media screen and (min-width: 601px) {
  .text10 {
    font-size: 10px !important;
  }

  .text11 {
    font-size: 11px !important;
  }

  .text12 {
    font-size: 12px !important;
  }

  .text14 {
    font-size: 14px !important;
  }

  .text16 {
    font-size: 16px !important;
  }

  .text18 {
    font-size: 18px !important;
    font-weight: bold;
  }

  .text20 {
    font-size: 20px !important;
  }

  .text22 {
    font-size: 22px !important;
  }

  .tag {
    display: flex;
    align-items: center;
  }

  .tag > p {
    height: fit-content;
    text-align: justify;
    margin: 0;
    padding: 0;
  }
}
