/* Input ---> */
.item {
  border-radius: 100px;
  height: fit-content;

  --inner-padding-top: 0;
  --inner-padding-bottom: 0;
  --padding-top: 0;
  --padding-bottom: 0;
}

.item ion-input {
  margin: 0 3vw;
  --placeholder-color: var(--ion-color-secondary);
  --placeholder-opacity: 80%;
  text-align: center;
}
.item ion-input input {
  height: fit-content;
}

/* <--- Input */

@media (min-width: 600px) {
  .item ion-input {
    font-size: 18px;
    --padding-top: 0;
    --padding-bottom: 0;
  }
}
@media (max-width: 599px) {
  .item ion-input {
    font-size: 4vw;
  }
}

.item::part(native) {
}
