.header ion-toolbar {
  --padding-top: 5vh;
  --padding-bottom: 0;
}

.header_grid > ion-row > ion-col {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.header_title > ion-col {
  justify-content: center;
}

.header_timespan > ion-col {
  justify-content: space-between;
  margin: 0 28vw;
}

.header_timespan ion-icon {
  font-size: 4vw;
}

.title {
  margin-top: 2vh;
}

.labels {
  margin-top: 2vh;
  margin-bottom: 1vh;
}

.labels > ion-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.labels_mid_col > ion-text {
  margin-left: 2vw;
}

.labels_mid_col > ion-icon {
  font-size: 5.5vw;
}

.room_info > ion-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bar > ion-progress-bar {
  /* .bar { */
  --background: #77a3b1;
  /* --background: red; */
  --progress-background: red;
  height: 14px;
  border-radius: 10px;
}
.bar > ion-progress-bar::part(progress) {
  color: red;
}

.rewards_title {
  margin-top: 2vh;
}
