.item {
  --border-color: var(--ion-color-primary);
  --inner-padding-top: 2vh;
  --inner-padding-bottom: 1vh;
  --inner-padding-start: 2vh;
  --inner-padding-end: 2vh;
}

.item::part(native) {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.item > ion-text > p {
  text-align: center;
}

.question {
  padding-bottom: 1vh;
}
