/* Text ---> */
.no_current_reservations-text-item {
  margin: 0 4vw;
  margin-top: 2vh;
}
div.no_current_reservations-text-item {
  margin: 0 5vw;
  margin-top: 5vh;
}

.no_current_reservations-text-item > ion-text {
  margin: 0;
  padding: 0;
}

.no_current_reservations-text-item > ion-text > p {
  font-size: 3.9vw;

  margin: 0 auto;

  /* opacity: 80%; */
}
.no_current_reservations-text-item > ion-text > p:last-child {
  margin-top: 2vh;
}
/* <--- Text */

/* Add Booking Button ---> */
.add_booking_button {
  margin-top: 2vh;
}

.add_booking_button ion-col {
  display: flex;
  justify-content: center;
}
.add_booking_button ion-button {
  height: 5vh;
  width: 70vw;
  --border-radius: 10px;
}

.add_booking_button p {
  font-size: 4.6vw;
  font-weight: bold;
}
/* <--- Add Booking Button */

/* Explore Button ---> */
.no_current_reservations-explore_button {
  margin-top: 3vh;
}

.no_current_reservations-explore_button > ion-button {
  height: 5vh;
  width: 70vw;
  --border-radius: 10px;

  margin: 0 auto;
}

.no_current_reservations-explore_button > ion-button > ion-text > h3 {
  font-family: Chivo;
  font-size: 4.6vw;

  margin: 0;
  padding: 0 2vw;
}
/* <--- Explore Button */
