.hotel_imgs {
  --ion-grid-padding: 0;
}

.hotel_imgs > ion-row > ion-col {
  --ion-grid-column-padding: 0;
  position: relative;
}

.edit_imgs {
  position: absolute;

  bottom: 0%;
  right: 5%;
}

.spending_title > ion-col {
  display: flex;
  justify-content: center;
}

.hotel_img {
  width: 100%;
  height: 25.5vh;
  background: var(--ion-color-tertiary);
}

.action_sheet-cancel > span {
  color: var(--ion-color-secondary);
}
