.menu-buttons {
  /* display: contents; */
  margin-top: 6vh;
  /* padding-top: 5vh; */
  padding: 0;
}

.menu-item {
  background-color: var(--ion-color-primary);
}

.menu-item > ion-item {
  padding: 0.5vh 0;
}

.menu-item p {
  text-align: end;
  font-size: 4.8vw;
  margin: 0;
}

.menu-item ion-icon {
  margin-left: 2vw;
  font-size: 6vw;
}

.menu-item-line {
  margin-left: 10vw;
  background: rgba(215, 233, 188, 1);
  min-height: 1px;
}
