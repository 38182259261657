.card {
  margin: 0 5vw;
  margin-top: 2vh;
}

.card > ion-img {
  width: 100%;
  height: 100%;
}

.card-content {
  padding: 0.5vh 3vw;
}

.card-content > ion-grid {
  --ion-grid-padding: 0;
}

.card-content > ion-grid > ion-row > ion-col {
  --ion-grid-column-padding: 0;
}

#card-content-no_data > ion-grid {
  --ion-grid-padding: 1vw;
}
