.gallery > ion-col {
  width: 30%;
  border-radius: 10px;

  --ion-grid-column-padding: 0;
}

.gallery ion-img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
