.selection_picker {
  width: 100%;
  height: fit-content;
}

.selection_picker > ion-col {
  display: flex;
  justify-content: space-between;
}

.selection_picker > ion-col p:last-child {
  margin-right: 2vw;
}

.selection_picker > ion-col > ion-select {
  --placeholder-color: var(--ion-color-secondary);
}

.selection_picker > ion-col > ion-select::part(icon) {
  display: none;
}

/* .selection_picker > ion-col > ion-select::part(placeholder) {
} */
.selection_picker > ion-col > ion-select::part(text) {
  color: var(--ion-color-secondary);
  font-size: 3.8vw;
}

@media (min-width: 600px) {
  .selection_picker > ion-col > ion-select::part(text) {
    font-size: 18px;
  }
}
