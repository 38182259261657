.card {
  padding: 0;
  margin: 0;
  width: 96%;
}

.card > ion-card-content {
  padding: 0.2vh 3vw;
}
.col {
  display: flex;
  justify-content: center;
}
