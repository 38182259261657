.buttons {
  margin-top: 2vh;
}

.buttons > ion-col {
  display: flex;
  justify-content: space-between;
}

.buttons > ion-col > ion-button {
  --border-radius: 13px;
}

@media screen and (max-width: 600px) {
  .buttons > ion-col > ion-button::part(native) {
    width: 28vw;
  }
  .buttons > ion-col {
    margin: 0 11vw;
  }
}
@media screen and (min-width: 601px) {
  .buttons > ion-col {
    margin: 0 100px;
  }
}

.buttons > ion-col > ion-button::part(native) {
  /* height: fit-content; */
  height: 4.5vh;
  min-height: 35px;
  padding: 1vh 2vw;
}
