/* DESKTOP */
.desktop_launch {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}

.desktop_launch-left {
  width: 50%;
  background: var(--ion-color-primary);

  display: flex;
  justify-content: center;
}
.desktop_launch-left > ion-img {
  width: 30vw;
  height: fit-content;
  margin-top: 30vh;
}

.desktop_launch-right {
  padding: 0 3vw;

  width: 50%;
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

.toggle_row {
  margin-top: 2vh;
}
.toggle_row > ion-col {
  display: flex;
  justify-content: flex-end;
}

.toggle {
  --background: var(--ion-color-primary);

  --handle-background: var(--ion-color-tertiary);
  --handle-background-checked: var(--ion-color-tertiary);

  --handle-width: 100px;
  width: 200px;
}

.toggle_login::part(track)::after {
  position: absolute;

  top: 5px;
  left: 25px;

  content: "Login";

  color: var(--ion-color-primary);
  font-size: 20px;

  z-index: 1;
}

.toggle_signup::part(track)::after {
  position: absolute;

  top: 5px;
  right: 22px;

  content: "Signup";

  color: var(--ion-color-primary);
  font-size: 20px;

  z-index: 1;
}
