.grid > ion-row {
  padding: 0vh 2vw;
}

.name h3 {
  font-size: 5.5vw;
  font-weight: bold;
}

.address {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.timespan {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.timespan p {
  font-size: 2.3vw;
  font-weight: bold;
}

.timespan > ion-icon {
  margin: 0 2vw;
  font-size: 4vw;
}
