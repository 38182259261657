.item {
  display: inline-block;
  margin-top: 2vh;
  margin-left: 5vw;
}

.item::part(native) {
  --min-height: 0px;
}

.item > ion-text > p {
  font-size: 3vw;

  margin: 0;
  text-decoration: underline;
}
