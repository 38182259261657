.menu_grid {
  background-color: var(--ion-color-secondary);
  --ion-grid-padding: 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.menu_grid ion-col {
  --ion-grid-column-padding: 0;
}

.button_row {
  margin: 0;
}

.icon {
  font-size: 3vh;
}

@keyframes open_close {
  0% {
    width: 60px;
  }

  50% {
    width: 130px;
  }

  100% {
    width: 200px;
  }
}

/* .menu_grid_open {
  animation-name: open_close;
  animation-duration: 1s;
}
.menu_grid_close {
  animation-name: open_close;
  animation-duration: 1s;
  animation-direction: reverse;
} */
