@import url(https://fonts.googleapis.com/css?family=Chivo);
@import url(https://fonts.googleapis.com/css?family=Montserrat);
@media screen and (min-width: 601px) {
  .styles_chivo12__qpGRz {
    font-size: 12px;
  }

  .styles_chivo14__3OZTN {
    font-size: 14px;
  }
  .styles_chivo16__1Ihiq {
    font-size: 16px;
  }

  .styles_chivo18__1sbfv {
    font-size: 18px;
    font-weight: bold;
  }

  .styles_chivo20__2TiVE {
    font-size: 20px;
  }

  .styles_chivo22__2tajM {
    font-size: 22px;
  }

  .styles_chivo24__2b0Nd {
    font-size: 24px;
    font-weight: bold;
  }

  .styles_chivo30__uKaiN {
    font-size: 30px;
  }

  .styles_chivo32__2xJo3 {
    font-size: 32px;
    /* font-size: 3.95vh; */
  }

  .styles_tag__2lG29 {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
  }

  .styles_tag__2lG29 > p {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
}

@media screen and (max-width: 600px) {
  .styles_chivo12__qpGRz {
    font-size: 3.1vw;
  }

  .styles_chivo14__3OZTN {
    font-size: 14px;
  }
  .styles_chivo16__1Ihiq {
    font-size: 16px;
  }

  .styles_chivo18__1sbfv {
    font-size: 5vw;
    font-weight: bold;
  }

  .styles_chivo20__2TiVE {
    font-size: 5.4vw;
    font-weight: bold;
  }

  .styles_chivo22__2tajM {
    font-size: 5.9vw;
    font-weight: bold;
  }

  .styles_chivo24__2b0Nd {
    font-size: 6.2vw;
    font-weight: bold;
  }

  .styles_chivo30__uKaiN {
    font-size: 6vw;
    font-weight: bold;
  }

  .styles_chivo32__2xJo3 {
    font-size: 8vw;
    font-weight: bold;
  }

  .styles_tag__2lG29 {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
  }

  .styles_tag__2lG29 > p {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
}

.styles_header__2fHPq ion-col {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.styles_header__2fHPq > ion-toolbar {
  --p: 2.2vh;
  --padding-bottom: var(--p);
  --padding-top: var(--p);
}

.styles_exit__2bu-J > ion-button > ion-icon {
  font-size: 4vw;
}

.styles_extra_button__1X1iy > button {
  width: 100%;
  height: 100%;
}
.styles_extra_button__1X1iy > button > ion-icon {
  font-size: 7vw;
}

@media screen and (min-width: 601px) {
  .styles_exit__2bu-J > ion-button > ion-icon {
    font-size: 20px;
  }
}

@media screen and (max-width: 600px) {
  .styles_text10__1cteU {
    font-size: 2.7vw !important;
  }

  .styles_text11__3b82z {
    font-size: 2.9vw !important;
  }

  .styles_text12__iGTFz {
    font-size: 3.2vw !important;
  }

  .styles_text14__2MZsb {
    font-size: 3.8vw !important;
  }

  .styles_text16__3Ls1q {
    font-size: 4.2vw !important;
  }

  .styles_text18__1GZb2 {
    font-size: 4.5vw !important;
    font-weight: bold;
  }

  .styles_text20__3-hQf {
    font-size: 5.3vw !important;
  }

  .styles_text22__1BDm4 {
    font-size: 22px !important;
  }

  .styles_tag__2FhUB {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
  }

  .styles_tag__2FhUB > p {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    text-align: justify;
    margin: 0;
    padding: 0;
  }
}
@media screen and (min-width: 601px) {
  .styles_text10__1cteU {
    font-size: 10px !important;
  }

  .styles_text11__3b82z {
    font-size: 11px !important;
  }

  .styles_text12__iGTFz {
    font-size: 12px !important;
  }

  .styles_text14__2MZsb {
    font-size: 14px !important;
  }

  .styles_text16__3Ls1q {
    font-size: 16px !important;
  }

  .styles_text18__1GZb2 {
    font-size: 18px !important;
    font-weight: bold;
  }

  .styles_text20__3-hQf {
    font-size: 20px !important;
  }

  .styles_text22__1BDm4 {
    font-size: 22px !important;
  }

  .styles_tag__2FhUB {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
  }

  .styles_tag__2FhUB > p {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    text-align: justify;
    margin: 0;
    padding: 0;
  }
}

.styles_item__1sZDb {
  --border-color: var(--ion-color-primary);
  --inner-padding-top: 2vh;
  --inner-padding-bottom: 1vh;
  --inner-padding-start: 2vh;
  --inner-padding-end: 2vh;
}

.styles_item__1sZDb::part(native) {
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.styles_item__1sZDb > ion-text > p {
  text-align: center;
}

.styles_question__2Zgmi {
  padding-bottom: 1vh;
}

.content_title__2LMzf{
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    font-weight: bolder;
    color: #73CE90;
    padding-bottom: 15px;
    padding-top: 15px;
    margin-top:8px;
    text-align: center;
}
.content_header__17aMh{
    background-color: white;
    line-height: normal;
    position: fixed;
    padding-bottom: 15px;
    padding-top:5px;
    border-top: 1px solid #62CD90;
    border-left: 1px solid #62CD90;
    border-right: 1px solid #62CD90;
    width: 100%;
    box-shadow: 0px 7px 6px -6px darkgrey; 
    
}

.content_contentTitle__3M52I {
    text-align: justify;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: bold;
    color: #1A697F;
    padding-top: 15px;
    padding-left:15px;
    padding-right: 15px;
    border-top: 0.5px solid #DCF2C4;

}

.content_question__2uACR{
    text-align: left;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    padding-top: 15px;
    padding-left:10px;
    padding-right: 10px;
}

.content_answer__32FF8{
    text-align: left;
    font-family: "Inter", sans-serif;
    font-size: 11px;
    line-height: 16px;
    color:#1A697F;
    padding-top: 8px;
    padding-left: 20px;
    padding-right: 20px;
}

.content_privacySection__22Ei7{
    background-color: #ffff;
    color: #1A697F;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    overflow-y: scroll;
    width: 100%;
    border: 1px solid #62CD90;
}

.content_closing__3qQ2q{
    color: #EA7F72;
    position:fixed;
    margin-left: 20px;
    margin-top: 20px;
    font-size: 24px;
}

.content_indice__3m_WA{
    text-align: left;
    font-family: "Inter", sans-serif;
    font-size: 15px;
    line-height: 16px;
    color:#1A697F;
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
}

@media(min-width:600px){
    .content_contactData__2MYrt {
        width:500px;
    }
}



.styles_item__2Ny2S {
  /* margin: 0 6vw; */
  --inner-padding-top: 1.5vh;
  --inner-padding-bottom: 1.5vh;

  --padding-start: 6vw;
  --padding-end: 6vw;
}

.styles_item__2Ny2S > ion-label {
  margin-left: 5vw;
}

.styles_icon__14ZRX {
  width: 25px;
  height: 15px;
}

.styles_separator__wOCcl {
  border: var(--ion-color-tertiary);
  background-color: var(--ion-color-tertiary);
  margin: 0 4vw;
  height: 1px;
}

.styles_flag__1cZxo {
  width: 7vw;
  height: 4vh;
}

.styles_header__2yOGo {
  background: #ffffff;
  /* margin-top: 2vh; */
}

/*  Header Border --- */
.styles_header-auth__l0UvB {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  --ion-grid-padding: 0;
}

.styles_header__2yOGo > ion-toolbar {
  /* padding-top: 2vh; */
  --border-style: none;
}

.styles_header__2yOGo::after {
  display: none;
}
/* --- Header Border */

.styles_header__2yOGo > ion-toolbar > ion-grid {
  padding-top: 2.6vh;
}

.styles_header-auth__l0UvB > ion-toolbar > ion-grid > ion-row > ion-col {
  --ion-grid-column-padding: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.styles_header-auth-backButton__1iwei > ion-button {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.styles_header-auth-backButton__1iwei > ion-button > ion-icon {
  width: 15vw;
  height: 3.5vh;
}

.styles_header-logo__3H5SN > ion-icon {
  width: 33vw;
  height: 6vh;
}

.styles_header-menu__9rvcI ion-icon,
.styles_header-non_auth-home__1Rss9 ion-icon {
  width: 9.2vw;
  height: 5vh;
}

#styles_page__3sHnH {
  --launch-page-buttons-width: 47%;
}

.styles_item-logo__Njisq {
  margin-top: 25vh;
}

.styles_item-logo__Njisq::part(native) {
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.styles_logo__1K_4h {
  width: 80%;
}

.styles_grid__3LHdi {
  margin-top: 10vh;
  width: 90%;
}

.styles_button__2Bzgq {
  width: 100%;
  height: 7vh;
}

/* DESKTOP */
.styles_desktop_launch__1IMrW {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  height: 100%;
  width: 100%;
}

.styles_desktop_launch-left__1y8XY {
  width: 50%;
  background: var(--ion-color-primary);

  display: -webkit-flex;

  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}
.styles_desktop_launch-left__1y8XY > ion-img {
  width: 30vw;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-top: 30vh;
}

.styles_desktop_launch-right__3cmTO {
  padding: 0 3vw;

  width: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  /* align-items: center; */
}

.styles_toggle_row__1TSiI {
  margin-top: 2vh;
}
.styles_toggle_row__1TSiI > ion-col {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.styles_toggle__2SQmy {
  --background: var(--ion-color-primary);

  --handle-background: var(--ion-color-tertiary);
  --handle-background-checked: var(--ion-color-tertiary);

  --handle-width: 100px;
  width: 200px;
}

.styles_toggle_login__2S1iQ::part(track)::after {
  position: absolute;

  top: 5px;
  left: 25px;

  content: "Login";

  color: var(--ion-color-primary);
  font-size: 20px;

  z-index: 1;
}

.styles_toggle_signup__1IU_2::part(track)::after {
  position: absolute;

  top: 5px;
  right: 22px;

  content: "Signup";

  color: var(--ion-color-primary);
  font-size: 20px;

  z-index: 1;
}

.styles_grid__2Sn1B {
  margin: 0 4vw;
  margin-top: 3vh;
  border: 1px solid var(--ion-color-danger);
  background-color: rgba(255, 0, 0, 0.3);
  border-radius: 10px;
}

.input-item {
  width: 100%;
}

.input-item > ion-label > p {
  font-size: 4vw;
}

.input-item > ion-input {
  font-size: 4vw;
}

.styles_button_container__1ogcu {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.styles_button_container__1ogcu > ion-button.styles_sc-ion-buttons-ios-s__2WbEe,
.styles_button_container__1ogcu > ion-button:not(.styles_button-round__aOX5k) {
  /* width: 90%; */
  --border-radius: 12px;
  height: 5vh;
  min-height: 40px;
}

.styles_small_text__VLLZV p {
  font-size: 3.8vw;
}

.styles_grid__3-rVM ion-label {
  margin: 0 2vw;
}

.styles_grid__3-rVM ion-label p {
  font-size: 2.5vh;
}

.styles_grid__3-rVM ion-input {
  font-size: 2.5vh;
}

.styles_grid__3-rVM ion-input > input {
  padding: 1vh 2vw !important;
}

.styles_grid__3-rVM > ion-row > ion-col {
  margin: 0 3vw;
}

.styles_separator___WYet {
  width: 100%;
  border: 1px solid var(--ion-color-primary);
}

.enter_text_modal_modal__10C-N {
  --min-height: 200px;
  /* --max-height: 200px; */
  --max-width: 90%;
  --border-radius: 10px;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.enter_text_modal_modal__10C-N > .enter_text_modal_modal-wrapper__1hOYV {
  position: absolute;
  top: 20%;
}

.enter_text_modal_modal__10C-N > ion-backdrop {
  -webkit-filter: blur(0px);
          filter: blur(0px);
}

.enter_text_modal_height1__2EAf5 {
  --height: 26vh;
}
.enter_text_modal_height2__QhMt2 {
  --height: 35vh;
}

@media screen and (min-width: 601px) {
  .enter_text_modal_modal__10C-N {
    --width: 500px;
  }
}

.styles_content__192JO {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

@media screen and (max-width: 600px) {
  .styles_input__1x-vf {
    margin: 0 5vw;
    margin-top: 2vh;
  }
}
@media screen and (min-width: 601px) {
  .styles_input__1x-vf {
    margin: 0 30px;
    margin-top: 2vh;
  }
}

.styles_buttons__1NrYm {
  margin-top: 2vh;
}

.styles_buttons__1NrYm > ion-col {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.styles_buttons__1NrYm > ion-col > ion-button {
  --border-radius: 13px;
}

@media screen and (max-width: 600px) {
  .styles_buttons__1NrYm > ion-col > ion-button::part(native) {
    width: 28vw;
  }
  .styles_buttons__1NrYm > ion-col {
    margin: 0 11vw;
  }
}
@media screen and (min-width: 601px) {
  .styles_buttons__1NrYm > ion-col {
    margin: 0 100px;
  }
}

.styles_buttons__1NrYm > ion-col > ion-button::part(native) {
  /* height: fit-content; */
  height: 4.5vh;
  min-height: 35px;
  padding: 1vh 2vw;
}

/* Input ---> */
.styles_item__WTiWL {
  border-radius: 100px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;

  --inner-padding-top: 0;
  --inner-padding-bottom: 0;
  --padding-top: 0;
  --padding-bottom: 0;
}

.styles_item__WTiWL ion-input {
  margin: 0 3vw;
  --placeholder-color: var(--ion-color-secondary);
  --placeholder-opacity: 80%;
  text-align: center;
}
.styles_item__WTiWL ion-input input {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

/* <--- Input */

@media (min-width: 600px) {
  .styles_item__WTiWL ion-input {
    font-size: 18px;
    --padding-top: 0;
    --padding-bottom: 0;
  }
}
@media (max-width: 599px) {
  .styles_item__WTiWL ion-input {
    font-size: 4vw;
  }
}

.styles_item__WTiWL::part(native) {
}

.styles_header__1wrfh {
  margin-top: 1vh;
}

.styles_header__1wrfh > ion-col {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.styles_header__1wrfh > ion-col > ion-icon {
  font-size: 6vw;
  font-weight: bold;
}

.styles_header__1wrfh ion-button {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.styles_row__3G2S0 ion-col {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;

  --ion-grid-column-padding: 0;
}

.styles_row__3G2S0 > ion-col p {
  text-align: center;
}

.styles_item__1Akbl {
  display: inline-block;
  margin-top: 2vh;
  margin-left: 5vw;
}

.styles_item__1Akbl::part(native) {
  --min-height: 0px;
}

.styles_item__1Akbl > ion-text > p {
  font-size: 3vw;

  margin: 0;
  text-decoration: underline;
}

.button-item > ion-button {
  position: relative;
  overflow: hidden;

  margin: 0;

  height: 100%;
  width: 100%;
}

.button-item > ion-button::part(native) {
  margin: 0;
  padding: 0;
  border-radius: 9px;
}

.button-item > ion-button > ion-text > p {
  font-size: 4.6vw;
  font-weight: bold;

  margin: 0;
}


.form-title {
  text-align: center;
}

.form-form {
  margin: 0 10vw;
  /* width: 80%; */
}

#login-page > .form-container > .form-form > .button-item {
  margin-top: 10vh;
}

#login-page > .form-container > .form-form {
  margin-top: 5vh;
}

.signup_signup-page__1QSzH > .signup_form-container__2xnL_ > .signup_form-form__sJz1n {
  margin-bottom: 5vh;
}

.signup_signup-page__1QSzH > .signup_form-container__2xnL_ > .signup_form-form__sJz1n > .signup_button-item__JJ3Dk {
  margin-top: 6vh;
}

.signup_greeting-thankyou_row__10YlI {
  margin: 13vh 0;
  margin-bottom: 0;
}

.signup_greeting-points_row__2Htgu {
  margin-top: 3vh;
}

.signup_greeting-button_row__Ei46n {
  margin-top: 8vh;
}

.signup_greeting-code_row__2B6uA {
  margin-top: 8vh;
}

.signup_greeting-description__1W7wz {
  margin-top: 2vh;
}


.styles_labels__2UNa2 {
  margin-top: 4vh;
}

.styles_label__H9DaR > ion-text {
  margin-left: 3vw;
}

.styles_menu_grid__2Ebzy {
  background-color: var(--ion-color-secondary);
  --ion-grid-padding: 0;

  display: -webkit-flex;

  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.styles_menu_grid__2Ebzy ion-col {
  --ion-grid-column-padding: 0;
}

.styles_button_row__3Plps {
  margin: 0;
}

.styles_icon__Zoc9f {
  font-size: 3vh;
}

@-webkit-keyframes styles_open_close__3xjCV {
  0% {
    width: 60px;
  }

  50% {
    width: 130px;
  }

  100% {
    width: 200px;
  }
}

@keyframes styles_open_close__3xjCV {
  0% {
    width: 60px;
  }

  50% {
    width: 130px;
  }

  100% {
    width: 200px;
  }
}

/* .menu_grid_open {
  animation-name: open_close;
  animation-duration: 1s;
}
.menu_grid_close {
  animation-name: open_close;
  animation-duration: 1s;
  animation-direction: reverse;
} */

.styles_grid__1H-Ri {
  border-radius: 12px;
  --ion-grid-padding: 0;
  /* height: 100%; */
}

.styles_grid__1H-Ri ion-button.styles_arrow__F0LyJ {
  height: 5vh;
}
.styles_grid__1H-Ri > ion-row ion-col {
  --ion-grid-column-padding: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.styles_center__20f3k {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.styles_grid__1H-Ri ion-button.styles_arrow__F0LyJ::part(native) {
  margin: 0;
  padding: 0;
}

.styles_right__nG7Mg,
.styles_left__mApLn {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.styles_slider__z9Fkg {
  width: 100%;
}

.styles_slider__z9Fkg ion-col {
  -webkit-justify-content: center;
          justify-content: center;
}

.styles_icon_next_to_text__3kr9o {
  margin-left: 15px;
  font-size: 25px;
}

@media screen and (min-width: 601px) {
  .styles_grid__1H-Ri {
    width: 350px;
  }

  .styles_icon__3UWK1 > ion-icon {
    font-size: 20px;
  }
}

@media screen and (max-width: 600px) {
  .styles_grid__1H-Ri {
    margin: 0 5vw;
  }

  .styles_grid__1H-Ri ion-icon {
    font-size: 3.5vw;
  }

  .styles_icon__3UWK1 > ion-icon {
    /* width: 10px; */
    font-size: 4vw;
  }

  .styles_slider__z9Fkg ion-col > ion-icon {
    font-size: 4.5vw;
  }

  .styles_grid__1H-Ri ion-button {
    height: 5.5vh;
  }

  .styles_main_text__GhRTm > ion-button {
    --padding-start: 0;
    --padding-end: 0;
    --padding-top: 0;
    --padding-bottom: 0;
  }
}

.styles_segments__1mLMs {
  margin: 0 5vw;
}

.styles_segments__1mLMs ion-segment {
  --background: none;
}

.styles_segments__1mLMs ion-segment-button {
  --background: var(--ion-color-light);
  --background-checked: var(--ion-color-light);
  --border-color: var(--ion-color-light);

  --indicator-color: none;
  --indicator-box-shadow: none;
}

.styles_segments__1mLMs ion-segment-button::part(indicator) {
  border: 1px var(--ion-color-secondary) solid;
  border-left: none;
  border-right: none;
  border-top: none;
}

.styles_segmented_filters__2jwVE {
  margin-top: 2vh;
}


.styles_slider_col__3xqCI {
  width: 100%;
  margin-top: 3vh;
}

.styles_graph__1Ioy3 {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.styles_graph__1Ioy3 {
  margin-top: 2vh;
}

.spending_data-item {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.spending_data-item-label > ion-label > p {
  margin: 0;
  font-size: 2.6vw;
}

.spending_data-item-content {
  margin-top: 0.5vh;
  display: -webkit-flex;
  display: flex;
  /* justify-content: space-between; */
}

.spending_data-item-content > ion-text {
  margin-left: 3vw;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.spending_data-item-content > ion-text > p {
  font-family: Chivo;
  font-size: 5.5vw;
  text-align: left;
}

.spending_data-item-content > ion-text > p > small {
  font-size: 3.4vw;
}

.styles_card__2J-kW {
  padding: 0;
  margin: 0;
  width: 96%;
}

.styles_card__2J-kW > ion-card-content {
  padding: 0.2vh 3vw;
}
.styles_col__3ois7 {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.modal_footer_container__1VsPQ {
  padding-bottom: 2vh;
  background-color: var(--ion-color-header);
}

.styles_container__1-c-Q ion-col {
  --ion-grid-column-padding: 0;
}

.styles_container__1-c-Q ion-button::part(native) {
  padding: 0;
  margin: 0;
}

.styles_container__1-c-Q ion-icon {
  font-size: 12.5vw;
}

.styles_header__35jGR > ion-toolbar {
  --p: 2.2vh;
  --padding-bottom: var(--p);
  --padding-top: var(--p);
}

.styles_header_grid__28gaS > ion-row > ion-col {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.styles_content_graph__3boqk > ion-col,
.styles_content_title__bxViO > ion-col {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.styles_content_title__bxViO {
  margin-top: 5vh;
}

.styles_content_graph__3boqk {
  margin-top: 5vh;
}

.styles_header_room__2albL {
  margin-top: 3vh;
}


.styles_gallery__2oByb > ion-col {
  width: 30%;
  border-radius: 10px;

  --ion-grid-column-padding: 0;
}

.styles_gallery__2oByb ion-img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.edit_content__h7OY4 > div {
  margin-top: 10vh;
  height: 80%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.edit_content__h7OY4 > div > ion-grid {
  max-height: 100%;
}

.edit_content__h7OY4 > ion-grid h3 {
  font-size: 5vw;
  font-weight: bold;
}

/* .content > ion-grid {
  --ion-grid-padding: 3vw;
} */

.edit_content__h7OY4 > ion-grid > ion-row > ion-col:last-child {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.edit_content__h7OY4 ion-textarea {
  border: 1px var(--ion-color-secondary) solid;
  --background: #fcfaf1;
  --color: var(--ion-color-secondary);

  --padding-start: 1vh;
  --padding-end: 1vh;

  min-height: 40vh;
}

.edit_title__2G1eU {
  background: var(--ion-color-light);
  width: 100%;

  --ion-grid-padding: 3vw;
}

@media (min-width: 600px) {
  .edit_title__2G1eU {
    --ion-grid-padding: 30px;
  }
}

.modal_footer2_footer__2TT7P > ion-toolbar {
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.25);
}

.modal_footer2_button_container__OHlZP {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.modal_footer2_button_container__OHlZP > ion-button.modal_footer2_sc-ion-buttons-ios-s__2so1P,
.modal_footer2_button_container__OHlZP > ion-button:not(.modal_footer2_button-round__3EExi) {
  width: 90%;
  --border-radius: 12px;
  height: 5vh;
}

.modal_footer2_button_container__OHlZP p {
  font-size: 4.9vw;
}

.about_title__3cCwE ion-col {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.about_text__IciN1 p {
  text-align: justify;
}

.additional_styles_to_markdown_hotel_description h2,
.additional_styles_to_markdown_hotel_description h1,
.additional_styles_to_markdown_hotel_description h3,
.additional_styles_to_markdown_hotel_description h4,
.additional_styles_to_markdown_hotel_description h5,
.additional_styles_to_markdown_hotel_description h6,
.additional_styles_to_markdown_hotel_description p {
  color: var(--ion-color-secondary);
}

.additional_styles_to_markdown_hotel_description h1,
.additional_styles_to_markdown_hotel_description h2,
.additional_styles_to_markdown_hotel_description h3 {
  font-weight: bold;
}

.additional_styles_to_markdown_hotel_description h1.green,
.additional_styles_to_markdown_hotel_description h2.green,
.additional_styles_to_markdown_hotel_description h3.green,
.additional_styles_to_markdown_hotel_description h4.green,
.additional_styles_to_markdown_hotel_description h5.green,
.additional_styles_to_markdown_hotel_description h6.green,
.additional_styles_to_markdown_hotel_description p.green {
  color: var(--ion-color-primary);
}

.edit_button_edit_button__226hB::part(native) {
  padding: 0;
}

@media (min-width: 600px) {
  .edit_button_edit_button__226hB ion-icon {
    font-size: 4vh;
  }
}

.edit_button_edit_button__226hB {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.address_container__1sVVH {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  /* justify-content: flex-end; */

  /* width: 100%; */
}

.address_container__1sVVH p {
  font-size: 2.3vw;
  text-align: center;
  text-justify: center;

  margin: 0;
}

.address_container__1sVVH > ion-icon {
  font-size: 3vw;
  margin-right: 1vw;
}

.hotel_name_address_grid__22zMz > ion-row {
  padding: 0vh 2vw;
}

.hotel_name_address_name__N7aFy h3 {
  font-size: 5.5vw;
  font-weight: bold;
}

.hotel_name_address_address__21FOD {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
}

.hotel_name_address_timespan__17zNg {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.hotel_name_address_timespan__17zNg p {
  font-size: 2.3vw;
  font-weight: bold;
}

.hotel_name_address_timespan__17zNg > ion-icon {
  margin: 0 2vw;
  font-size: 4vw;
}

.styles_hotel_imgs__3QtW_ {
  --ion-grid-padding: 0;
}

.styles_hotel_imgs__3QtW_ > ion-row > ion-col {
  --ion-grid-column-padding: 0;
  position: relative;
}

.styles_edit_imgs__9a2ly {
  position: absolute;

  bottom: 0%;
  right: 5%;
}

.styles_spending_title__14Cs8 > ion-col {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.styles_hotel_img__-Io4m {
  width: 100%;
  height: 25.5vh;
  background: var(--ion-color-tertiary);
}

.styles_action_sheet-cancel__1-tB8 > span {
  color: var(--ion-color-secondary);
}

.styles_modal__bqCKE {
  --height: 310px;
  --width: 90%;
  --border-radius: 10px;
}

ion-menu#menu {
  --width: 50vw;
  /* width: 130vw; */
}

.menu-header {
  padding-top: 3vh;
}

.menu-logo > ion-img {
  position: absolute;
  right: 5%;
  width: 85%;
  height: auto;
}

.menu-close {
  color: var(--ion-color-light);
}

.menu-close ion-icon {
  font-size: 8vw;
}

.menu-footer {
  border-top: 1px solid rgba(26, 105, 127, 0.4);
  height: 9vh;

  position: absolute;
  bottom: 0%;
  left: 0%;
  right: 0%;

  display: -webkit-flex;

  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.menu-footer > ion-item {
  width: 100%;
}

.menu-footer ion-grid {
  --ion-grid-padding: 2.6vh;
  -webkit-padding-end: 0;
          padding-inline-end: 0;
  -webkit-padding-start: 0;
          padding-inline-start: 0;
}

.menu-footer-icon {
  display: -webkit-flex;
  display: flex;
  margin-left: 3vw;
  -webkit-align-items: center;
          align-items: center;
}

.menu-buttons {
  /* display: contents; */
  margin-top: 6vh;
  /* padding-top: 5vh; */
  padding: 0;
}

.menu-item {
  background-color: var(--ion-color-primary);
}

.menu-item > ion-item {
  padding: 0.5vh 0;
}

.menu-item p {
  text-align: end;
  font-size: 4.8vw;
  margin: 0;
}

.menu-item ion-icon {
  margin-left: 2vw;
  font-size: 6vw;
}

.menu-item-line {
  margin-left: 10vw;
  background: rgba(215, 233, 188, 1);
  min-height: 1px;
}

.styles_grid__CgWn3 {
  /* --ion-grid-padding: 0; */
}
.styles_title__UkI-V {
  --ion-grid-column-padding: 5vw;
}

.styles_options_picker__1WBTB {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 0vh 0;
  padding-bottom: 0.7vh;
  padding-bottom: 5px;
  /* background-color: red; */
}

.styles_options_picker__1WBTB > ion-col {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.styles_options_picker__1WBTB > ion-col p:last-child {
  /* margin-right: 2vw; */
}

.styles_container__38wJZ {
  margin-bottom: 0.6vh;
}

.styles_container__38wJZ ion-datetime {
  padding: 0;
  --padding-start: 0;
  /* --padding-end: 3vw; */
  --padding-top: 0;
  --padding-bottom: 0;
  margin-right: 5vw;
}

.styles_container__38wJZ ion-button {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 0;
}

.styles_container__38wJZ ion-datetime {
  margin-right: 19px;
}

.styles_container__38wJZ ion-datetime::part(text),
.styles_container__38wJZ ion-datetime::part(placeholder) {
  font-family: Montserrat;
  font-size: 3.8vw;
  color: var(--ion-color-secondary);
  /* vertical-align: center; */
  /* text-align: center; */
  /* margin-right: 0; */
  /* --padding-end: 0;
  --padding-start: 0;
  --padding-top: 0;
  --padding-bottom: 0; */
}

@media (min-width: 600px) {
  .styles_container__38wJZ ion-datetime::part(text),
  .styles_container__38wJZ ion-datetime::part(placeholder) {
    font-size: 16px;
  }
}
@media (max-width: 599px) {
  .styles_container__38wJZ ion-datetime::part(text),
  .styles_container__38wJZ ion-datetime::part(placeholder) {
    font-size: 3.8vw;
  }
}

.styles_picker_row__3cNjR > ion-col {
  --ion-grid-column-padding: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}


.styles_skeleton__1YtVF {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;

  margin: 0 5vw;
}

.styles_skeleton__1YtVF ion-skeleton-text {
  border-radius: 15px;
}

.styles_list_button__ojlnz {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 0;

  --padding-start: 0;
  --padding-end: 0;
  --padding-top: 0;
  --padding-bottom: 0;
}

.styles_button-content__3sY_A {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 100%;

  --ion-grid-column-padding: 0;
}

.styles_modal__2pq5K {
  --width: 300px;
  --height: 165px;
}

.styles_grid__1EQ8R {
  /* height: 100%; */
}
.styles_grid__1EQ8R > div {
  /* height: 100%; */
}

.styles_grid__1EQ8R > ion-row {
  height: 100%;
}


.styles_grid-left-col__3VOqQ {
  height: 100%;
}
.styles_segments__2yQjc {
  margin: 0 5vw;
}

/* .segments ion-segment {
  --background: none;
} */

.styles_segments__2yQjc ion-segment-button {
  /* --background: var(--ion-color-light); */
  /* --background-checked: var(--ion-color-light); */
  --border-color: var(--ion-color-light);

  --indicator-color: none;
  --indicator-box-shadow: none;
}

.styles_segments__2yQjc ion-segment-button::part(indicator) {
  border: 1px var(--ion-color-secondary) solid;
  border-left: none;
  border-right: none;
  border-top: none;
}

@media screen and (max-width: 600px) {
  .styles_button__17DiD ion-icon {
    font-size: 10vw;
  }
}

@media screen and (min-width: 601px) {
  .styles_button__17DiD ion-icon {
    font-size: 40px;
  }
}

.styles_button__3Vloa {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.styles_button__3Vloa > ion-button {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 10px;
}

.styles_button__3Vloa > ion-button::part(native) span {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

@media screen and (max-width: 600px) {
  .styles_button__3Vloa > ion-button::part(native) {
    height: 5vh;
  }

  .styles_icon__2VlV_ {
    font-size: 6vw;
  }

  .styles_button__3Vloa > ion-button > ion-text {
    margin-left: 3vw;
  }
}

@media screen and (min-width: 601px) {
  .styles_button__3Vloa > ion-button::part(native) {
    height: 50px;
    /* height: 5vh; */
  }

  .styles_icon__2VlV_ {
    font-size: 25px;
  }

  .styles_button__3Vloa > ion-button > ion-text {
    margin-left: 10px;
  }
}

.styles_date__1J6f2 {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
}

.styles_card__11_Pq,
.styles_card__11_Pq > ion-card-content {
  padding: 0;
}

.styles_right__34VaV,
.styles_left__Ul_7y {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

.styles_left__Ul_7y {
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.styles_right__34VaV {
  -webkit-align-items: flex-end;
          align-items: flex-end;
}

.styles_estimated_value__39Npj > ion-col {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.styles_header__3_-zZ ion-toolbar {
  --padding-top: 5vh;
  --padding-bottom: 0;
}

.styles_header_grid__1F7r3 > ion-row > ion-col {
  display: -webkit-flex;
  display: flex;
  /* justify-content: center; */
  -webkit-align-items: center;
          align-items: center;
}

.styles_header_title__2i8iV > ion-col {
  -webkit-justify-content: center;
          justify-content: center;
}

.styles_header_timespan__3UTk0 > ion-col {
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin: 0 28vw;
}

.styles_header_timespan__3UTk0 ion-icon {
  font-size: 4vw;
}

.styles_title__2MT5u {
  margin-top: 2vh;
}

.styles_labels__3O9m- {
  margin-top: 2vh;
  margin-bottom: 1vh;
}

.styles_labels__3O9m- > ion-col {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.styles_labels_mid_col__YHh4X > ion-text {
  margin-left: 2vw;
}

.styles_labels_mid_col__YHh4X > ion-icon {
  font-size: 5.5vw;
}

.styles_room_info__ucWuE > ion-col {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.styles_bar__2fhJ- > ion-progress-bar {
  /* .bar { */
  --background: #77a3b1;
  /* --background: red; */
  --progress-background: red;
  height: 14px;
  border-radius: 10px;
}
.styles_bar__2fhJ- > ion-progress-bar::part(progress) {
  color: red;
}

.styles_rewards_title__1BalI {
  margin-top: 2vh;
}

.styles_margin__2K7Kd {
  margin: 0 4vw;
}

.styles_selection_picker__1tGg4 {
  padding: 0vh 6vw;
  padding-bottom: 5px;
}

@media screen and (max-width: 600px) {
  .styles_input__3t4J7 {
    --padding-start: 3vw;
    --padding-end: 3vw;
    --padding-top: 1vh;
    --padding-bottom: 1vh;
  }
}
@media screen and (min-width: 601px) {
  .styles_input__3t4J7 {
    --padding-start: 10px;
    --padding-end: 10px;
    --padding-top: 7px;
    --padding-bottom: 7px;
  }
}

.styles_input__2H_tM {
  margin: 0 4vw;
}

.styles_selection_picker__3hxUO {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.styles_selection_picker__3hxUO > ion-col {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.styles_selection_picker__3hxUO > ion-col p:last-child {
  margin-right: 2vw;
}

.styles_selection_picker__3hxUO > ion-col > ion-select {
  --placeholder-color: var(--ion-color-secondary);
}

.styles_selection_picker__3hxUO > ion-col > ion-select::part(icon) {
  display: none;
}

/* .selection_picker > ion-col > ion-select::part(placeholder) {
} */
.styles_selection_picker__3hxUO > ion-col > ion-select::part(text) {
  color: var(--ion-color-secondary);
  font-size: 3.8vw;
}

@media (min-width: 600px) {
  .styles_selection_picker__3hxUO > ion-col > ion-select::part(text) {
    font-size: 18px;
  }
}

.styles_header_timespan__1BAUm ion-icon {
  font-size: 20px;
}

.styles_labels__2TdW8 {
  margin: 0 300px;
  margin-top: 2vh;
  margin-bottom: 1vh;
}

.styles_labels__2TdW8 > ion-col {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.styles_labels_mid_col__3lBB0 > ion-text {
  margin-left: 10px;
}

.styles_labels_mid_col__3lBB0 > ion-icon {
  font-size: 25px;
}

.styles_room_info__MDJSj {
  margin: 0 300px;
}

.styles_room_info__MDJSj > ion-col {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.styles_bar__R1DuW > ion-progress-bar {
  /* .bar { */
  --background: #77a3b1;
  /* --background: red; */
  --progress-background: red;
  height: 14px;
  border-radius: 10px;

  /* width: 10%; */
}
.styles_bar__R1DuW > ion-progress-bar::part(progress) {
  color: red;
}

.styles_rewards_title__1DRhs {
  margin-top: 2vh;
}

.styles_modal__y_sfv {
  --height: 475px;
}

.styles_clicker_picker__sGff2 {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 0vh 0;
  padding-bottom: 0.7vh;
  padding-bottom: 5px;
  /* background-color: red; */
}

.styles_clicker_picker__sGff2 > ion-col {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

@media screen and (max-width: 600px) {
  .styles_textarea__mpkA2 {
    margin: 0 4vw;
  }
  .styles_title__70sdv {
    margin: 0 2vw;
  }
  .styles_description_title__23uHa {
    margin: 0 4vw;
  }
}
@media screen and (min-width: 601px) {
  .styles_textarea__mpkA2 {
    margin: 0 10px;
  }
  .styles_title__70sdv {
    margin: 0 10px;
  }
  .styles_description_title__23uHa {
    margin: 0 10px;
  }
}

.styles_grid__KIwJw > ion-row {
  margin-top: 0.5vh;
}

@media screen and (max-width: 600px) {
  .styles_textarea__1q2ZM {
    padding: 0 3vw;
  }
}
@media screen and (min-width: 601px) {
  .styles_textarea__1q2ZM {
    padding: 0 10px;
  }
}

.styles_modal__1XfP0 {
  --height: 100%;
}

.styles_card__mpM8y {
  margin: 1.5vh 1vw;

  /* width: 25vw; 90px*/
  /* height: 25vw; 90px */

  display: -webkit-flex;

  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.styles_card__mpM8y > ion-card-content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;

  padding: 0;
  height: 100%;
}

/* .card ion-icon {
  font-size: 10vw;
} */

.styles_card__mpM8y ion-text {
  margin-top: 1vh;
}

.styles_grid__9IOKp > ion-row {
  margin: 0 3vw;
}

.styles_radio_button__3AipR {
  height: 100%;
  width: 100%;
  border: 2px solid var(--ion-color-secondary);
  border-radius: 50%;

  display: -webkit-flex;

  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.styles_radio_button__3AipR > div {
  width: 80%;
  height: 80%;
  border: 2px solid var(--ion-color-secondary);
  background-color: var(--ion-color-secondary);
  border-radius: 50%;
}

.styles_header__6pDFk {
  margin-top: 2vh;
}

.styles_header__6pDFk > ion-col {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;

  margin: 0 6vw;
}

.styles_header_right__37opA {
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}
.styles_header_right__37opA > ion-icon {
  font-size: 7vw;
}

.styles_header_left__2B_7H > ion-icon {
  font-size: 5vw;
}
.styles_header_left__2B_7H > ion-text {
  margin-left: 3vw;
}

.styles_list-card_container__17aKy {
  display: -webkit-flex;
  display: flex;
}

.styles_list-card_container__17aKy > div {
  margin: 0;
  height: 90%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  width: 360px;
  border-radius: 10px;
}

.styles_list-card_container__17aKy > div > ion-card {
  width: 100%;
  padding: 1vh 0;
}

.styles_checkbox_container__2L1hJ {
  width: 20px;
  height: 20px;
  border: 3px solid var(--ion-color-secondary);
  border-radius: 50%;
}

.styles_checkbox__2cxhj {
  --background: var(--ion-color-light);
  /* --border-color: var(--ion-color-secondary); */
  --border-width: 0px;
  --border-color-checked: var(--ion-color-secondary);
  --checkmark-color: var(--ion-color-secondary);
  --background-checked: var(--ion-color-secondary);

  --size: 10px;
}

.styles_checkbox__2cxhj::part(mark) {
  width: 50%;
}

.styles_content_col__2b2Kw {
  padding-top: 4vh;
}

.current_res-card-content {
  padding: 0 2vw 0vh 2vw;
}

.current_res-card-header {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 1.5vh 5vw 1.5vh 5vw;
}

/* Title --- */
.current_res-card-header > div > ion-card-title > h4 {
  margin: 0;
  font-size: 20px;
  font-size: 5vw;
  font-weight: bold;
}
/* --- Title */

/* Subtitle --- */
.current_res-card-header > div > ion-card-subtitle {
  display: -webkit-flex;
  display: flex;
  margin: 0;
  margin-top: 0.4vh;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 50vw;
}

.current_res-card-header > div > ion-card-subtitle > ion-icon {
  font-size: 3vw;
}

.current_res-card-header > div > ion-card-subtitle > p {
  font-weight: 100;
  margin: 0;
  margin-right: 1vw;
  font-size: 2.5vw;
  -webkit-align-self: center;
          align-self: center;
}
/* --- Subtitle */

/* Date --- */
.current_res-card-header-date {
  margin-top: 1.5vh;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.current_res-card-header-date > p {
  font-size: 2.3vw;
  margin: 0;
}
/* --- Date */

.current_res-card {
  margin: 3vh 4vw 2vh 4vw;
}

/* Text ---> */
.no_current_reservations-text-item {
  margin: 0 4vw;
  margin-top: 2vh;
}
div.no_current_reservations-text-item {
  margin: 0 5vw;
  margin-top: 5vh;
}

.no_current_reservations-text-item > ion-text {
  margin: 0;
  padding: 0;
}

.no_current_reservations-text-item > ion-text > p {
  font-size: 3.9vw;

  margin: 0 auto;

  /* opacity: 80%; */
}
.no_current_reservations-text-item > ion-text > p:last-child {
  margin-top: 2vh;
}
/* <--- Text */

/* Add Booking Button ---> */
.add_booking_button {
  margin-top: 2vh;
}

.add_booking_button ion-col {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}
.add_booking_button ion-button {
  height: 5vh;
  width: 70vw;
  --border-radius: 10px;
}

.add_booking_button p {
  font-size: 4.6vw;
  font-weight: bold;
}
/* <--- Add Booking Button */

/* Explore Button ---> */
.no_current_reservations-explore_button {
  margin-top: 3vh;
}

.no_current_reservations-explore_button > ion-button {
  height: 5vh;
  width: 70vw;
  --border-radius: 10px;

  margin: 0 auto;
}

.no_current_reservations-explore_button > ion-button > ion-text > h3 {
  font-family: Chivo;
  font-size: 4.6vw;

  margin: 0;
  padding: 0 2vw;
}
/* <--- Explore Button */

/* Data --- */

.data_container__qMr2X {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.data_data-top__Goakc p {
    font-weight: bold;
    font-size: 4.5vw;
}

.data_data-top__Goakc p>small {
    font-size: 2.5vw;
}

.data_data-bottom__1Ymyi p {
    font-size: 3.7vw;
}


/* --- Data */
/* Hotel Info --- */
.hotel_info_container__2Axkm {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.hotel_info_container__2Axkm > ion-text > p {
  margin: 0;
}

.hotel_info_hotel_name__cTPs8 > p {
  font-size: 5vw;
  font-weight: bold;
}

.hotel_info_timespan__2b7ql > p {
  font-size: 2.5vw;
}

/* --- Hotel Info */

.hotel_card_card__31KCG {
  margin: 0 5vw;
  margin-top: 2vh;
}

.hotel_card_card__31KCG > ion-img {
  width: 100%;
  height: 100%;
}

.hotel_card_card-content__C0JlY {
  padding: 0.5vh 3vw;
}

.hotel_card_card-content__C0JlY > ion-grid {
  --ion-grid-padding: 0;
}

.hotel_card_card-content__C0JlY > ion-grid > ion-row > ion-col {
  --ion-grid-column-padding: 0;
}

#hotel_card_card-content-no_data__1Ip9d > ion-grid {
  --ion-grid-padding: 1vw;
}

.reservations_list-list {
  margin-top: 1.5vh;
  margin-bottom: 1.5vh;
}

ion-list-header {
  padding: 0;
}

.content_title__cwetI {
  text-align: center;
  margin-top: 3vh;
}

.content_title__cwetI > ion-text > h2 {
  font-size: 7vw;
}

.content_graph__3orkA {
  margin-top: 7vh;
}

.content_labels__1luzA {
  margin-top: 4vh;
}

.content_label__3sId4 > ion-text {
  margin-left: 3vw;
}

.header_container__3JFCI {
  --ion-grid-padding: 0;
}

.header_container__3JFCI ion-col {
  --ion-grid-column-padding: 0;

  display: -webkit-flex;

  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.header_container__3JFCI h1,
.header_container__3JFCI p {
  text-align: center;
}

.header_row1__1CcUL {
  margin-top: 4vh;
  /* background-color: green; */
}

.header_row2__19uR- {
  margin-top: 3vh;
  /* background-color: blue; */
}

.header_calendar__3AcGf ion-button,
.header_graph_type__1528c ion-button {
  /* background-color: red; */
  font-size: 4vw;
}

.header_calendar__3AcGf ion-button::part(native),
.header_graph_type__1528c ion-button::part(native) {
  padding: 0;
  margin: 0;
}

.header_hotel_name__o9W9a > ion-text > h1 {
  font-size: 6.5vw;
}

.header_timeline__1nZw6 > ion-text > p {
  font-size: 4vw;
}

.data_modal-content {
  position: relative;
}

.data_modal-content .data_modal-content-spinner {
  position: absolute;
  top: 45%;
  left: 45%;
}

.content_item__b4NwS {
  margin: 0 5vw;
  margin-top: 1vh;

  --border-color: var(--ion-color-tertiary);
}

.content_item__b4NwS:first-child {
  margin-top: 1.5vh;
}

.content_grid__3Y-ny {
  --ion-grid-padding: 0;
}

.content_grid__3Y-ny > ion-row:first-child > ion-col {
  --ion-grid-column-padding: 0;
}

.styles_data__1I_A8 > ion-row:last-child {
  padding-bottom: 1vh;
}

.styles_data__1I_A8 ion-col {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

/** DATA ---> */
.styles_data-type__1THEM {
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.styles_data-type__1THEM ion-icon {
  font-size: 6vw;
}

.styles_data-type__1THEM p {
  font-size: 2.7vw;
  margin-right: 2vw;
}
/** <--- DATA */

/** VALUE ---> */
.styles_value__2IrLu p {
  font-size: 5.3vw;
}

.styles_value__2IrLu small {
  font-size: 2.4vw;
}

.styles_value__2IrLu ion-text[color="danger"] p {
  font-size: 3vw;
}
/** <--- VALUE */

.styles_data__6JurB > ion-row:last-child {
  padding-bottom: 1vh;
}

.styles_data__6JurB ion-col {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

/** DATA ---> */
.styles_data-type__1WOrt {
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.styles_data-type__1WOrt ion-icon {
  font-size: 6vw;
}

.styles_data-type__1WOrt p {
  font-size: 2.7vw;
  margin-right: 2vw;
}
/** <--- DATA */

/** VALUE ---> */
.styles_value__1dWYr p {
  font-size: 5.3vw;
}

.styles_value__1dWYr small {
  font-size: 2.4vw;
}

.styles_value__1dWYr ion-text[color="danger"] p {
  font-size: 3vw;
}
/** <--- VALUE */

.title_title__2RftH p {
  font-size: 5vw;
}

.title_title__2RftH {
  padding-bottom: 1vh;
}

.header_container__2sGo_ > ion-toolbar {
  --padding-top: 0;
  --padding-bottom: 0;
  --padding-start: 0;
  --padding-end: 0;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.styles_grid__1Gek3 {
  --ion-grid-padding: 0;
}

.styles_grid__1Gek3 ion-col {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.styles_left__2qe49 {
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.styles_left__2qe49 > ion-avatar {
  width: 18vw;
  height: 18vw;
}

.styles_right__3d9yh {
  -webkit-align-items: flex-end;
          align-items: flex-end;
}

.styles_right__3d9yh ion-text {
  width: 100%;
}
.styles_right__3d9yh ion-text > p {
  width: 100%;
  text-align: center;
}

.leaderboard-ranking {
  margin-top: 1vh;
  background-color: var(--ion-color-primary);
}

/* Header ---> */
.leaderboard-ranking-header p {
  font-size: 4vw;
  font-weight: 400;
  margin: 0;
}

.leaderboard-ranking-header > ion-row > ion-col:first-child > ion-text > p {
  text-align: start;
  margin-left: 15vw;
}
.leaderboard-ranking-header > ion-row > ion-col:last-child > ion-text > p {
  text-align: end;
  margin-right: 5vw;
}
/* <--- Header */

/* User ---> */
.leaderboard-user-item {
  --border-color: var(--ion-color-tertiary);
}
.leaderboard-user-item > ion-grid {
  margin: 0 3vw 0 5vw;
}

.leaderboard-user-item p {
  margin: 0;
}

.leaderboard-user-footprint > p {
  font-size: 4.5vw;
  font-weight: bold;

  text-align: end;
  margin-right: 5vw;
}

.leaderboard-user-name > p {
  font-size: 4.8vw;
}

.leaderboard-user-points > p {
  font-size: 3.2vw;
  margin-left: 5vw;
}

/* <--- User */

.leaderboard {
  width: 100%;
}

.styles_friends__VIdVg,
.styles_avatar__auugI {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;

  -webkit-justify-content: center;

          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.styles_name__1ryCO {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
}

.styles_avatar__auugI > ion-avatar {
  width: 17vw;
  height: 17vw;
  border: 3px var(--ion-color-primary) solid;
}

.styles_grid__2_kVD {
  --ion-grid-padding: 0;
}
.styles_card_content__3I6-B {
  padding: 2vh;
}


.stats_card {
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.stats_card > ion-card-content {
  padding: 0.5vh 1vw;
}

.stats_card-container {
  margin-top: 1.5vh;
}

.tip_card-item {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.tip_card {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-top: 2vh;
  margin-bottom: 1vh;
}

/* ! Title ---> */
.tip_card > ion-card-title {
  padding-left: 4vw;
  padding-top: 1vh;
}
.tip_card > ion-card-title > ion-item > ion-icon {
  font-size: 8vw;
}
.tip_card > ion-card-title > ion-item > ion-text {
  margin-left: 3vw;
}
.tip_card > ion-card-title > ion-item > ion-text > p {
  font-size: 5vw;
}
/* ! <--- Title */

/* ! Content ---> */
.tip_card > ion-card-content {
  padding: 0;
  padding-left: 15vw;
  padding-right: 4vw;
  padding-bottom: 1vh;
}

.tip_card > ion-card-content > ion-text > p {
  font-size: 3.8vw;
}
/* ! <--- Content */

.styles_container__q8I3Y {
  /* margin-top: 1vh; */
  padding: 0;
}

.styles_separator__1XbTi {
  border: var(--ion-color-tertiary);
  background-color: var(--ion-color-tertiary);
  margin: 0 5vw;
  height: 1px;
}

/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
/* :root { */
body,
body.light {
  --ion-color-primary: #62cd90;
  --ion-color-primary-rgb: 98, 205, 144;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #56b47f;
  --ion-color-primary-tint: #72d29b;

  --ion-color-primary_dim: #62c890;
  --ion-color-primary_dim-rgb: 98, 200, 144;
  --ion-color-primary_dim-contrast: #000000;
  --ion-color-primary_dim-contrast-rgb: 0, 0, 0;
  --ion-color-primary_dim-shade: #56b07f;
  --ion-color-primary_dim-tint: #72ce9b;

  --ion-color-secondary: #1a697f;
  --ion-color-secondary-rgb: 26, 105, 127;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #175c70;
  --ion-color-secondary-tint: #31788c;

  --ion-color-tertiary: #d7e9bc;
  --ion-color-tertiary-rgb: 215, 233, 188;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #bdcda5;
  --ion-color-tertiary-tint: #dbebc3;

  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #ff8373;
  --ion-color-danger-rgb: 255, 131, 115;
  --ion-color-danger-contrast: #000000;
  --ion-color-danger-contrast-rgb: 0, 0, 0;
  --ion-color-danger-shade: #e07365;
  --ion-color-danger-tint: #ff8f81;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #c4c4c4;
  --ion-color-medium-rgb: 196, 196, 196;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #acacac;
  --ion-color-medium-tint: #cacaca;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-color-secondary_dim: #77a3b1;

  --ion-color-beje: #fcfaf1;
  --ion-color-beje-rgb: 252, 250, 241;
  --ion-color-beje-contrast: #000000;
  --ion-color-beje-contrast-rgb: 0, 0, 0;
  --ion-color-beje-shade: #dedcd4;
  --ion-color-beje-tint: #fcfbf2;

  /* NAVBAR */
  --ion-color-navbar: #1a697f;
  --ion-color-navbar-rgb: 26, 105, 127;
  --ion-color-navbar-contrast: #ffffff;
  --ion-color-navbar-contrast-rgb: 255, 255, 255;
  --ion-color-navbar-shade: #175c70;
  --ion-color-navbar-tint: #31788c;

  /* MENU */
  --ion-color-menu_button: #1a697f;
  --ion-color-menu_button-rgb: 26, 105, 127;
  --ion-color-menu_button-contrast: #ffffff;
  --ion-color-menu_button-contrast-rgb: 255, 255, 255;
  --ion-color-menu_button-shade: #175c70;
  --ion-color-menu_button-tint: #31788c;

  /* BACKGROUND */
  --ion-color-background: #f4f5f8;
  --ion-color-background-rgb: 244, 245, 248;
  --ion-color-background-contrast: #000000;
  --ion-color-background-contrast-rgb: 0, 0, 0;
  --ion-color-background-shade: #d7d8da;
  --ion-color-background-tint: #f5f6f9;

  --ion-color-woocommerce: #9b5c8f;
  --ion-color-woocommerce-rgb: 155, 92, 143;
  --ion-color-woocommerce-contrast: #ffffff;
  --ion-color-woocommerce-contrast-rgb: 255, 255, 255;
  --ion-color-woocommerce-shade: #88517e;
  --ion-color-woocommerce-tint: #a56c9a;
}

body.dark {
  --ion-color-primary: #363739;
  --ion-color-primary-rgb: 54, 55, 57;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #303032;
  --ion-color-primary-tint: #4a4b4d;

  --ion-color-secondary: #404144;
  --ion-color-secondary-rgb: 64, 65, 68;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #38393c;
  --ion-color-secondary-tint: #535457;

  /* NAVBAR */
  --ion-color-navbar: #2a2b2e;
  --ion-color-navbar-rgb: 42, 43, 46;
  --ion-color-navbar-contrast: #ffffff;
  --ion-color-navbar-contrast-rgb: 255, 255, 255;
  --ion-color-navbar-shade: #252628;
  --ion-color-navbar-tint: #3f4043;

  /* BACKGROUND */
  --ion-color-background: #1f2023;
  --ion-color-background-rgb: 31, 32, 35;
  --ion-color-background-contrast: #ffffff;
  --ion-color-background-contrast-rgb: 255, 255, 255;
  --ion-color-background-shade: #1b1c1f;
  --ion-color-background-tint: #353639;

  /* MENU */
  --ion-color-menu_button: #f4f5f8;
  --ion-color-menu_button-rgb: 244, 245, 248;
  --ion-color-menu_button-contrast: #000000;
  --ion-color-menu_button-contrast-rgb: 0, 0, 0;
  --ion-color-menu_button-shade: #d7d8da;
  --ion-color-menu_button-tint: #f5f6f9;

  /* HEADER */
  --ion-color-header: #2a2b2e;
  --ion-color-header-rgb: 42, 43, 46;
  --ion-color-header-contrast: #ffffff;
  --ion-color-header-contrast-rgb: 255, 255, 255;
  --ion-color-header-shade: #252628;
  --ion-color-header-tint: #3f4043;
}

.ion-color-background {
  --ion-color-base: var(--ion-color-background);
  --ion-color-base-rgb: var(--ion-color-background-rgb);
  --ion-color-contrast: var(--ion-color-background-contrast);
  --ion-color-contrast-rgb: var(--ion-color-background-contrast-rgb);
  --ion-color-shade: var(--ion-color-background-shade);
  --ion-color-tint: var(--ion-color-background-tint);
}

.ion-color-header {
  --ion-color-base: var(--ion-color-header);
  --ion-color-base-rgb: var(--ion-color-header-rgb);
  --ion-color-contrast: var(--ion-color-header-contrast);
  --ion-color-contrast-rgb: var(--ion-color-header-contrast-rgb);
  --ion-color-shade: var(--ion-color-header-shade);
  --ion-color-tint: var(--ion-color-header-tint);
}

.ion-color-navbar {
  --ion-color-base: var(--ion-color-navbar);
  --ion-color-base-rgb: var(--ion-color-navbar-rgb);
  --ion-color-contrast: var(--ion-color-navbar-contrast);
  --ion-color-contrast-rgb: var(--ion-color-navbar-contrast-rgb);
  --ion-color-shade: var(--ion-color-navbar-shade);
  --ion-color-tint: var(--ion-color-navbar-tint);
}
.ion-color-menu_button {
  --ion-color-base: var(--ion-color-menu_button);
  --ion-color-base-rgb: var(--ion-color-menu_button-rgb);
  --ion-color-contrast: var(--ion-color-menu_button-contrast);
  --ion-color-contrast-rgb: var(--ion-color-menu_button-contrast-rgb);
  --ion-color-shade: var(--ion-color-menu_button-shade);
  --ion-color-tint: var(--ion-color-menu_button-tint);
}

.ion-color-primary_dim {
  --ion-color-base: var(--ion-color-primary_dim);
  --ion-color-base-rgb: var(--ion-color-primary_dim-rgb);
  --ion-color-contrast: var(--ion-color-primary_dim-contrast);
  --ion-color-contrast-rgb: var(--ion-color-primary_dim-contrast-rgb);
  --ion-color-shade: var(--ion-color-primary_dim-shade);
  --ion-color-tint: var(--ion-color-primary_dim-tint);
}

.ion-color-secondary_dim {
  --ion-color-base: var(--ion-color-secondary_dim);
}

.ion-color-beje {
  --ion-color-base: var(--ion-color-beje);
  --ion-color-base-rgb: var(--ion-color-beje-rgb);
  --ion-color-contrast: var(--ion-color-beje-contrast);
  --ion-color-contrast-rgb: var(--ion-color-beje-contrast-rgb);
  --ion-color-shade: var(--ion-color-beje-shade);
  --ion-color-tint: var(--ion-color-beje-tint);
}

.ion-color-woocommerce {
  --ion-color-base: var(--ion-color-woocommerce);
  --ion-color-base-rgb: var(--ion-color-woocommerce-rgb);
  --ion-color-contrast: var(--ion-color-woocommerce-contrast);
  --ion-color-contrast-rgb: var(--ion-color-woocommerce-contrast-rgb);
  --ion-color-shade: var(--ion-color-woocommerce-shade);
  --ion-color-tint: var(--ion-color-woocommerce-tint);
}

#root ion-tabs {
  position: relative;
}

button {
  background: none;
}

button:hover {
  opacity: 50%;
}

.sc-ion-modal-ios-h {
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.4);
}

.card-content-ios p {
  font-size: inherit;
}

p,
h1,
h2,
h3,
h4,
h5 {
  text-transform: none;
}

ion-item {
  --padding-start: 0;
  --padding-end: 0;
  --padding-left: 0;
  --padding-right: 0;
  --inner-padding-start: 0;
  --inner-padding-end: 0;
  --inner-padding-left: 0;
  --inner-padding-right: 0;
}

.montserrat {
  font-family: Montserrat;
}

.chivo {
  font-family: Chivo;
}

ion-loading > .loading-wrapper {
  --spinner-color: var(--ion-color-primary);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

ion-loading .loading-wrapper > .loading-content {
  color: var(--ion-color-secondary);
  margin-top: 2vh;
}

.fixed {
  background: white;
  left: 0%;
  right: 0%;
}

p,
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}

ion-card {
  -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.hidden {
  visibility: "hidden";
}

.shadow_b {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.shadow_t {
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.25);
}

.flexr-center,
.flexc-end,
.flexr-start,
.flexr-end,
.flexc-center,
.flexr-bot_left,
.flexr-bot_center,
.flexr-sb {
  display: -webkit-flex;
  display: flex;
}

.flexr-center,
.flexc-center,
.flexr-start,
.flexr-sb {
  -webkit-align-items: center;
          align-items: center;
}
.flexr-center,
.flexc-center,
.flexr-bot_center {
  -webkit-justify-content: center;
          justify-content: center;
}

.flexr-sb {
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.flexr-center,
.flexr-end,
.flexr-start,
.flexr-bot_left,
.flexr-sb {
  -webkit-flex-direction: row;
          flex-direction: row;
}

.flexc-center,
.flexc-end {
  -webkit-flex-direction: column;
          flex-direction: column;
}

.flexr-end {
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}
.flexc-end,
.flexr-bot_center {
  -webkit-align-items: flex-end;
          align-items: flex-end;
}
.flexr-start {
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

.flexr-bot_left {
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

#navbar {
  height: 9%;
}

#navbar ion-tab-button {
  --color-selected: var(--ion-color-secondary);
}

#navbar > ion-tab-button > ion-label {
  font-family: Chivo;
  font-size: 3.2vw;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: center;
  margin: 0;
}

#navbar > ion-tab-button > ion-icon {
  font-size: 8vw;
}

#navbar > ion-tab-button[tab="alerts"] {
  padding-top: 1.5vw;
}
#navbar > ion-tab-button[tab="alerts"] > ion-icon {
  font-size: 6.5vw;
}
#navbar > ion-tab-button[tab="hotels"] {
  padding-top: 1vw;
}
#navbar > ion-tab-button[tab="hotels"] > ion-icon {
  font-size: 7vw;
}
#navbar > ion-tab-button[tab="rewards"] {
  padding-top: 1.5vw;
}
#navbar > ion-tab-button[tab="rewards"] > ion-icon {
  font-size: 6.5vw;
}

@media (min-width: 500px) {
  #navbar > ion-tab-button > ion-label {
    font-family: Chivo;
    font-size: 3vw;
    font-style: normal;
    font-weight: 200;
    letter-spacing: 0em;
    text-align: center;
    margin: 0;
  }

  #navbar > ion-tab-button > ion-icon {
    font-size: 4vw;
  }
}

#root {
  --login_signup-title-margin-top: 10vh;
  --login_signup-form-margin-top: 10vh;
  --login_signup-form-button-width: 45%;
  --login_signup-form-button-height: 6vh;
}

.login_singup-page > .form-container,
#complete_profile-content > .form-container {
  margin-top: 6vh;
}

.login_singup-page > .form-container > .form-form {
  margin-top: 0vh;
  /* padding-bottom: 5vh; */
}

.login_singup-page > .form-container > .form-form > .button-item {
  width: 45%; /* button width */
  margin: 0 auto;
}

.login_singup-page > .form-container > .form-form > .button-item > ion-button {
  height: 6vh;
  min-height: 40px;
}

