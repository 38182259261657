.card {
  margin: 1.5vh 1vw;

  /* width: 25vw; 90px*/
  /* height: 25vw; 90px */

  display: flex;
  align-items: center;
  justify-content: center;
}

.card > ion-card-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  padding: 0;
  height: 100%;
}

/* .card ion-icon {
  font-size: 10vw;
} */

.card ion-text {
  margin-top: 1vh;
}
