.item {
  /* margin: 0 6vw; */
  --inner-padding-top: 1.5vh;
  --inner-padding-bottom: 1.5vh;

  --padding-start: 6vw;
  --padding-end: 6vw;
}

.item > ion-label {
  margin-left: 5vw;
}

.icon {
  width: 25px;
  height: 15px;
}

.separator {
  border: var(--ion-color-tertiary);
  background-color: var(--ion-color-tertiary);
  margin: 0 4vw;
  height: 1px;
}
