.grid {
  --ion-grid-padding: 0;
}

.grid ion-col {
  display: flex;
  flex-direction: column;
}

.left {
  justify-content: center;
  align-items: center;
}

.left > ion-avatar {
  width: 18vw;
  height: 18vw;
}

.right {
  align-items: flex-end;
}

.right ion-text {
  width: 100%;
}
.right ion-text > p {
  width: 100%;
  text-align: center;
}
