.data > ion-row:last-child {
  padding-bottom: 1vh;
}

.data ion-col {
  display: flex;
  align-items: center;
}

/** DATA ---> */
.data-type {
  justify-content: flex-end;
}

.data-type ion-icon {
  font-size: 6vw;
}

.data-type p {
  font-size: 2.7vw;
  margin-right: 2vw;
}
/** <--- DATA */

/** VALUE ---> */
.value p {
  font-size: 5.3vw;
}

.value small {
  font-size: 2.4vw;
}

.value ion-text[color="danger"] p {
  font-size: 3vw;
}
/** <--- VALUE */
