@media screen and (max-width: 600px) {
  .textarea {
    padding: 0 3vw;
  }
}
@media screen and (min-width: 601px) {
  .textarea {
    padding: 0 10px;
  }
}
