.edit_button::part(native) {
  padding: 0;
}

@media (min-width: 600px) {
  .edit_button ion-icon {
    font-size: 4vh;
  }
}

.edit_button {
  height: fit-content;
  width: fit-content;
}
