.grid {
  /* height: 100%; */
}
.grid > div {
  /* height: 100%; */
}

.grid > ion-row {
  height: 100%;
}


.grid-left-col {
  height: 100%;
}